import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Footer, PageHeader } from 'components';

const Term = ({ history }) => {
  return (
    <div className="omny-registerTerm">
      <PageHeader history={history} />

      <div className="term-content container">
        <p>
          <strong>
            Please read all CollNect Software Terms of License &amp; Use
            carefully before accessing or using the platform Software Service.
            By accessing or using the Service, you agree to be bound by the
            terms and conditions set forth below.{' '}
          </strong>
          Any use of, purchase through, this web site is expressly conditioned
          upon your acceptance of all terms and conditions stated herein and any
          others that may be available on{' '}
          <a href="http://www.CollNectSoftware.com">www.CollNectSoftware.com</a>
          . DO NOT USE THIS PLATFORM, PURCHASE COLLNECT CREDITS, OR OTHERWISE
          ACCESS COLLNECT&rsquo;S SERVICES THROUGH THIS PLATFORM
          <strong>, </strong>if &nbsp;you do not accept all the terms and
          conditions stated on this website.
        </p>
        <p>
          Subject to terms and conditions shown below, CollNect Software grants
          the subscriber a single non-exclusive month-to-month or subscription
          limited use license (The &ldquo;License&rdquo;), that may not be
          sub-licensed, transferred or assigned by the subscriber to access the
          platform Software-as-a-Service (&ldquo;PaaS&rdquo;) services of
          CollNect Software to manage texts to consenting established Customers,
          employees or persons so desiring Customer to reach them by text
          message. CollNect Software reserves the right to suspend, limit or
          cancel this license subject to the following terms and conditions of
          the License. CollNect Software reserves the right to modify the terms
          and conditions of this Limited License at any time.
        </p>
        <p>
          Under access and use of your account and the CollNect Software, you
          agree to be bound by all terms and conditions set out below. Any use
          or purchase of electronic credits through the platform are expressly
          conditioned on your compliance with this terms and conditions as
          established hereunder and occasionally updated from time-to-time by
          CollNect Software. DO NOT SIGN UP FOR THIS SERVICE, USE THE SERVICES,
          CREATE AN ACCOUNT OR PURCHASE ELECTRONIC CREDITS WITHIN THIS PLATFORM
          if you <u>do not</u> accept any of the below terms and conditions.
          These terms and conditions and any other offered unit are subject to
          The Electronic Signatures in Global and National Commerce Act (E-sign
          Act) from year 2000, as amended and constitute an agreement between
          CollNect and You, the Account User, and not for anyone else. By
          choosing &ldquo;I accept&rdquo; to these Terms and Conditions it means
          user consent to be bound. WHEN YOU ACTIVATE YOUR COLLNECT ACCOUNT OR
          USE ANY OF THE SERVICES, YOU REPRESENT THAT YOU HAVE THE LEGAL AGE TO
          ACCEPT THIS LICENSE AGREEMENT AND THAT YOU HAVE READ AND COMPLETELY
          UNDERSTAND THESE TERMS AND CONDITIONS FOR THIS LIMITED LICENSE
          AGREEMENT AND APPLICATION USE.
        </p>
        <p>
          <strong>
            <u>WARNING</u>
          </strong>
          : It is a Federal AND State Offense to use communication services
          including text messages &nbsp;and software for <u>any</u> fraudulent
          means or purpose. All Services furnished or performed through this
          platform shall not be used for any unlawful purpose.&nbsp; CollNect
          shall comply with all lawful legal requests for information regarding
          its accounts and Customers in the event of Fraud.
        </p>
        <h3>CollNect Software License &amp; Customer Terms of Use</h3>
        <p>
          These Terms of Purchase constitute an agreement
          (&quot;Agreement&quot;) between CollNect Software, Inc. <em>d/b/a</em>{' '}
          CollNect (&ldquo;CollNect,&ldquo; &ldquo;we,&rdquo; or
          &ldquo;us&rdquo;) and the purchaser (&ldquo;Customer&rdquo; or
          &ldquo;you&rdquo;) for residential and small business products offered
          by CollNect (&ldquo;Service&ldquo;) and where an electronic or written
          Agreement does not exist for services purchased from CollNect and
          purchased by the Customer through this website.
        </p>
        <p>
          BY PURCHASING SERVICES THROUGH THIS WEBSITE OR ACCOUNT PORTAL, YOU
          REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER INTO THIS AGREEMENT AND
          THAT YOU HAVE READ AND UNDERSTAND FULLY THE TERMS AND CONDITIONS OF
          THIS AGREEMENT.
        </p>
        <p>
          <strong>
            By accessing this website or purchasing products therein, you agree
            to be bound by the terms and conditions set forth below. If you do
            not wish to be bound by these terms and conditions, do not purchase
            services through this website or account portal. If you utilize the
            Services offered in a manner inconsistent with these terms and
            conditions, CollNect may unilaterally terminate access, block your
            future access and/or seek such additional relief as the
            circumstances of your misuse indicate is proper.&nbsp;
          </strong>
        </p>
        <p>
          The Following Terms are applicable to <u>all</u> CollNect Licensed
          Services offered or Rendered by this Platform using its PaaS Service:
        </p>
        <p>
          <strong>
            <u>Governing Law, Jurisdiction &amp; Venue</u>
          </strong>
          . These Terms of Use shall be governed exclusively and construed in
          strict accordance with the laws of the State of Georgia.&nbsp;
          Customer, by virtue of purchase through this website, agrees to submit
          to exclusive jurisdiction and venue of the state and federal courts of
          Fulton County Georgia, and, expressly waive all defenses to
          jurisdiction without any consideration of conflict of law theories,
          case law or principles.
        </p>
        <p>
          <strong>
            <u>Electronic Contract for Service.</u>
          </strong>
          &nbsp; All services, including licensed use of CollNect, are agreed by
          electronic format contract&nbsp; that exclusively govern their terms
          and requirements.&nbsp; CollNect shall make available your agreement
          in electronic format upon request. You may request access to these
          electronic records in either Adobe Acrobat or standard text
          formats.&nbsp; At all times, you retain the right to request and
          receive documents on paper upon request to CollNect via e-mail or
          fax.&nbsp;
        </p>
        <p>
          <strong>
            <u>Grant of Limited License of Use</u>
          </strong>
          <strong>:</strong> Subject to these Terms and Conditions CollNect
          hereby grants to you, the Customer, and only the Customer (as named in
          your CollNect account), a non-assignable, non-transferrable, limited
          license and right to access and use the Virtual Platform of On-Demand
          Text Message management services as on a Platform-as-a-Service (PaaS)
          basis inclusive of its pre-developed, software, Software-as-a-Service
          (SaaS) functions and methodologies, and features, the rights to which
          the Customer agrees exclusively agrees vests in CollNect, that enable
          Customer to provision, operate and manage their respective text
          messages used in business from the CollNect virtual platform insofar
          as CollNect&rsquo;s fees and all associated costs, charges and
          expenses derived from the Customer&rsquo;s use of the PaaS Solution as
          well as all credits purchased for the carriage of transmission are
          prepaid, or the Customer maintains a positive prepaid balance with
          CollNect under these Terms and Conditions. This Service is intended
          only to provide text message management and remission for lawful
          purposes and only within the United States of America.
        </p>
        <p>
          <strong>
            <u>Licensed Accounts</u>
          </strong>
          :&nbsp; CollNect shall provide Customer with a dedicated Account,
          Account Profile, Login and password credentials to enable Customer to
          use the PaaS under the Limited License of Use.&nbsp; The account types
          shall vary based upon the volume and functionality of use the Customer
          desires.&nbsp; Charges of CollNect for expanded account volumes and
          use shall be established in pricing, which may be revised from time to
          time by CollNect at its sole discretion.&nbsp; CollNect reserves the
          right to expand or restrict functionality of its expanded accounts and
          their use of the PaaS for logistical, technical and compliance
          purposes.&nbsp; Collnect is not a telecommunications carrier or IP
          Enabled Provider that transmits text messages or SMS messages.&nbsp;
          CollNect has existing carrier(s) connected to its network that
          undertake the transmission of on-demand messaging as a part of the
          service.&nbsp; The expense these carriers charge is charged to the
          Customer by means of the prepaid electronic credits of CollNect that
          are purchased.&nbsp; CollNect reserves the sole right to increase or
          decrease the amount electronic credits charged for message
          transmission to reflect then-current rates or charges of the
          carriers/providers connected to its platform who render the
          service.&nbsp; Please check pricing before using this feature of the
          PaaS to ensure sufficient credit balances for desired
          transmission.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
        </p>
        <p>
          <strong>
            <u>Limited License of Use Restrictions</u>
          </strong>
          <strong>:</strong>&nbsp; Customer shall not, nor shall it permit or
          assist others, (a) to modify or fraudulently use the CollNect PaaS
          platform or Software; (b) to access and de-engineer CollNect&rsquo;s
          software or Proprietary Materials that enable PaaS Services for any
          purpose other than those expressly authorized, (c) to process or
          permit to be processed the data of any third party that is not
          expressly authorized herein to access; or (iv) violate any of the
          following agreed conditions of Use:
        </p>
        <ol>
          <li>
            <strong>
              <u>
                Customer&rsquo;s Compliance with Commercial Text Message Laws
                &amp; Rules
              </u>
            </strong>
            .&nbsp;As a material condition of these Terms of Use of
            CollNect&rsquo;s PaaS Service,&nbsp;Customer agrees that for any use
            of the system to contact the telephone numbers of subscriber in the
            United States, the state and federal laws apply (including the laws
            of the State of Florida)&nbsp;and
            govern&nbsp;telemarketing/telephone solicitation conducted by SMS or
            other forms of text message may apply to any use of the CollNect
            PaaS platform or Service(s).&nbsp; In doing so, Customer agrees that
            the
            <strong> Federal Telephone Consumer Protection Act of 1991 </strong>
            specifically<strong>&nbsp;</strong>
            <strong>47 USC&sect; 227(c)(5)(C) </strong>and
            <strong> 16 CFR&sect; 310.4(b)(3) </strong>and
            <strong>
              {' '}
              47 U.S.C. &sect;&nbsp;227(b)(1)(A)&nbsp;(&ldquo;TCPA
            </strong>
            &rdquo;) in conjunction with
            <strong>
              &nbsp; FCC&nbsp;CG Docket No. 02-278 Report and Order (Feb. 15,
              2012){' '}
            </strong>
            are material compliance obligations of the Customer that must be
            observed prior to and when using the CollNect PaaS (&ldquo;TCPA
            Laws&rdquo;)<strong>.</strong>&nbsp;<strong> </strong>Customer
            further recognizes that the CollNect PaaS system may be considered
            an ADTS in some jurisdictions of the U.S. For purposes of clarity,
            the Federal Communications Commission (&ldquo;FCC&rdquo;) revised
            its Rules in 2012 in relation to telemarketing and the use of Auto
            Dialers (Robo-Calls) to residential and mobile telephones
            under&nbsp;
            <em>
              Rules and Regulations Implementing the Telephone Consumer
              Protection Act of 1991, CG Docket No. 02-278 Report and Order,
              February 15, 2012.{' '}
            </em>
            Important compliance obligations of the Customer under this
            restriction of Use,&nbsp;at a minimal, include recognition that:
            <ol style={{ listStyleType: 'lower-alpha;' }}>
              <li>
                The FTC, State and FCC Rules now require&nbsp;
                <strong>prior express written consent</strong> of the party
                being texted and this must be obtained before lawfully sending a
                marketing, sales or promotional text message. Customer agrees
                and warrants it will comply with this obligation in any Use of
                the CollNect PaaS.&nbsp;
              </li>
              <li>
                All messages in the&nbsp;PaaS will include the message to
                &ldquo;Cancel&rdquo; or &ldquo;Stop&rdquo;&nbsp;to allow a
                called or contacted party to revoke consent, or otherwise
                opt-out, and Customer agrees and warrants that it shall not
                remove this message from any marketing, sales or promotional
                message it sends through the PaaS.
              </li>
            </ol>
          </li>
          <li>
            Customer recognizes that pursuant to{' '}
            <strong>47 USC &sect; 227(c)(5)(C)</strong>&nbsp;and&nbsp;
            <strong>16 CFR &sect; 310.4(b)(3),&nbsp;</strong>it. shall not
            undertaker or cause to be made unwanted telephone solicitations to
            consumers or consumer telephone numbers for the sale of their
            services (Do-Not-Call laws or &rdquo;DNC laws&rdquo;)&nbsp;
          </li>
          <li>
            Customer agrees to abide by these laws and regulations and shall
            maintain practices and policies to avoid violations of TCPA
            or&nbsp;DNC laws as a material condition of its use of the
            PaaS.&nbsp;
          </li>
        </ol>
        <p>
          <strong>
            <u>Prohibited Content for Messaging:</u>
          </strong>
          &nbsp; CollNect has a zero-tolerance on certain types of businesses
          and messaging due to their impact on consumers. These types of
          business are of a type that involves high-risk prepositions to
          consumer, uses high pressure tactics, offers telemarketing in
          violation of the law indirectly,&nbsp; involves collection methods
          without the consent of the consumer, or otherwise comprises deceptive
          marketing via text messages. Furthermore, blind text telemarketing
          messaging is prohibited.&nbsp; Collnect also has a zero-tolerance of
          offensive material, hate speech, and content that is or encourages the
          abuse of minors. If CollNect determines, in its sole informed opinion,
          that such messaging as found below is being transmitted by a user of
          our Platform, it will then be necessary for us to protect our legal
          rights in the Platform and reasonable business interests, and either
          immediately block the functionality of messaging from your Account,{' '}
          <u>OR</u>, suspend your Account pending a full investigation of any
          possible misuse of our Services.&nbsp; These types of messages include
          the following content:
        </p>
        <p>
          <strong>
            Marketing of High-Risk Consumer Financial Services &amp; Use of
            messaging in Collections&nbsp;
          </strong>
        </p>
        <ul>
          <li>Payday Loans</li>
          <li>Short Term High Interest Loans</li>
          <li>Auto Loans</li>
          <li>Mortgage Loans</li>
          <li>Student Loans</li>
          <li>Debt Collection</li>
          <li>Gambling</li>
          <li>Stock Alerts</li>
          <li>Cryptocurrency</li>
        </ul>
        <p>
          <strong>
            High Risk Consumer Business Opportunity Offers and Multi-Level
            Programs
          </strong>
        </p>
        <ul>
          <li>Deceptive Work from Home Programs</li>
          <li>Risk Investment Opportunities</li>
          <li>Multi-Level Marketing</li>
        </ul>
        <p>
          <strong>
            3rd Party Telemarketing Solicitations on Consumer Credit and
            Debt&nbsp;{' '}
          </strong>
        </p>
        <ul>
          <li>Debt Consolidation</li>
          <li>Debt Reduction</li>
          <li>Credit Repair Programs</li>
          <li>Lead Generation</li>
        </ul>
        <p>
          <strong>
            Controlled Substance Advertisements or Solicitations&nbsp;
          </strong>
        </p>
        <ul>
          <li>Tobacco</li>
          <li>Vape</li>
          <li>Federally Illegal Drugs</li>
        </ul>
        <p>
          <strong>
            Fraudulent, Offensive or Abusive Messages or images links
          </strong>
          <strong>&nbsp;</strong>
        </p>
        <ul>
          <li>Abuse of Minors</li>
          <li>Pornography</li>
          <li>Profanity or Hate Speech</li>
          <li>Phishing</li>
          <li>Fraud or Scams</li>
          <li>Deceptive Marketing</li>
        </ul>
        <p>
          <strong>
            <u>Suspension of Access and Use</u>
          </strong>
          <strong>:</strong>&nbsp; CollNect retains the exclusive right to
          suspend or reactive this Limited License of Use at any time under
          these Term and Conditions, and for any reason or cause vested to
          CollNect under their discretion.&nbsp; Customer agrees that suspension
          of access and use does not terminate or cancel amounts owed Collect
          nor entitle Customer to refund of electronic Credits prepaid or
          otherwise give cause to relieve Customer of its obligations herein
        </p>
        <p>
          <strong>
            <u>Electronic Credits for SMS messages</u>
          </strong>
          . Transmission credits, or CollNect Credits, can be purchased through
          your platform account for remission of Customer&rsquo;s text messages.
          These credits are for on-demand message transmission purposes only and
          have no redeemable cash value once purchased. Customer acknowledges
          and agrees that such credits are for specific purpose and service only
          and that once prepaid can only be used by the Customer for the
          intended purpose and use on the platform. No cash refunds are given
          without cause. The underlying services of transmitting text messages
          is furnished by telecommunications carriers and IP Enabled
          Communication providers who in like fashion charge CollNect for the
          Services and offer no refunds. Should an issue arise in transmission,
          please alert our Customer Service team to either restore the credit
          balance for the service defect or outage and document the problem for
          reporting to the underlying carrier or provider. Electronic credits
          are a closed-loop value system used only for purposes and purchase of
          text message transmission through carriers and providers.
        </p>
        <p>
          <strong>
            <u>Cancellation of License Termination of Use</u>
          </strong>
          . This Limited License and access to the platform and associated
          software may be cancelled in one of two manners:
        </p>
        <ol>
          <li>
            <strong>
              <u>&nbsp;By Customer</u>
            </strong>
            . You, the Customer, may cancel this Limited License of Use at any
            time upon written notice and coordination with our Customer Service
            Team available at 1-877-0388 or e-mail it direct at{' '}
            <a href="mailto:support@CollNect.com">support@CollNect.com</a>.
            Verbal and written confirmation of you, as either an individual or
            officer or manager of a company, may be required by CollNect to
            complete the cancellation process.&nbsp; You may be asked for
            feedback, and/or other similar questions. at the time of
            cancellation to improve our Services.&nbsp; You may be also asked
            about any material issues in your use of the system at the time of
            cancellation. These are conditions necessary for cancellation to
            which you agree as a part of granting this License.
          </li>
          <li>
            <strong>
              <u>By CollNect</u>
            </strong>
            . CollNect may immediately cancel this Limited License, at its sole
            discretion, for any misuse, abuse, misrepresentations, unlawful
            activity or breach of the Terms and Conditions of its Limited
            License by the Customer. CollNect may cancel or suspend your account
            for any fraudulent activity, violations of TCPA Laws and Rules, or
            DNC Laws. Statements, representations, and confirmation of
            Customer&rsquo;s compliance with laws (either written or by
            electronic signature)are material to the grant of this
            License.&nbsp; CollNect may also cancel this License for any account
            inactivity, either by lack prepayment balance replenishment or use,
            which &nbsp;extends eleven (11) months beyond last use of Service to
            either send text messages or otherwise use the Service for its
            intended purposes. A notice of inactivity may be sent to Customer by
            CollNect to alert it of the inactivity and that within a thirty-day
            period Customer&rsquo;s account will be canceled and closed. Upon
            notice, if Customer desires to continue to use its Limited License,
            it must contact CollNect at 1-877-0388 or e-mail it direct at{' '}
            <a href="mailto:support@CollNect.com">support@CollNect.com</a> to
            cure inactive status and continue using the Service under this
            License, otherwise account will be deemed cancelled and a new
            account must be established by the Customer.
          </li>
          <li>
            <strong>
              <u>Effect of Cancellation</u>
            </strong>
            . Whether cancellation is by Customer or CollNect, the effect shall
            be that: (i) Customer&rsquo;s user account and access shall be
            cancelled; (ii); All prepaid balances remaining shall be deemed
            property of CollNect; &nbsp;(iii) All templates and features
            customized by the Customer within the Platform shall be removed from
            the system;&nbsp; and (iv) All data, including all telephone
            numbers, verified telephone numbers, lists, returned messages or any
            other communications using and recorded in the platform will be
            removed from the system.&nbsp; If Customer desires copy of any data
            after the termination date and account closure, they must contact
            CollNect at 1-877-777-0388 or e-mail it direct at{' '}
            <a href="mailto:support@CollNect.com">support@CollNect.com</a>.
            CollNect will determine if data is recoverable, or not, as requested
            by the Customer and may charge independently on a project basis for
            any data recovery service of a cancelled client. Such recovery work
            shall be on a prepaid basis only. CollNect reserves the sole right
            to retain any Customer record for any applicable period of statute
            of limitation purposes and to produce such records upon lawful legal
            request or otherwise as required by law or law enforcement.
          </li>
        </ol>
        <p>
          <strong>
            <u>No Refunds Without Cause</u>
          </strong>
          . CollNect will not offer any refunds or reimbursements for purchases
          without cause.&nbsp; Should Customers experience problems with their
          products or units purchased they must first contact our Customer
          service line at 1-800-877-777-0388 or e-mail it direct at{' '}
          <a href="mailto:support@CollNect.com">support@CollNect.com</a> to
          determine the nature of problem or whether a refund is applicable.
          CollNect exclusively reserves the right to determine whether a demand
          for refund is with or without cause.
        </p>
        <p>
          <strong>
            <u>Billing and Payment of Charges</u>
          </strong>
          . All Services for Use of the platform are prepaid by the Customer.
          The Customer is responsible for paying from prepaid balances all
          charges posted to its account or in an individual purchase, monthly
          reoccurring fees for licensed use, and applicable taxes or other
          charges related to the particular transaction, Services or other
          purchases through the platform. Prevailing pricing within the account
          or on our website shall be applicable to charges against prepaid
          balances. Charges, including disputed amounts, must be paid or debited
          by the due date shown on any invoice or within the user&rsquo;s
          account. If CollNect accepts late or partial payments or payments, it
          will not waive any of CollNect&rsquo;s rights hereunder to full
          amounts owed nor will it constitute an accord or satisfaction for any
          disputed or partially prepaid amount. CollNect&rsquo;s records
          (electronic or otherwise) are agreed as final stated accounting for
          Services and Use under this License and deemed to be deemed final by
          the Customer.
        </p>
        <p>
          <strong>
            <u>Credit Card Charges</u>
          </strong>
          . No Credit Cards payments will be accepted by CollNect from Credit
          Card Issuers that are not regulated Credit Card Issuers in the United
          States of America &ndash; U.S. Credit Cards only. If Customer pays by
          credit card, it thereby authorizes CollNect to charge the credit card
          account number for CollNect for the particular transaction selected
          and confirmed on our website. CollNect may terminate your account at
          any time, in CollNect&rsquo;s sole discretion, for declined credit
          cards or any other non-payment of account charges with the
          understanding, however, that Customer remains fully liable to CollNect
          for all charges accrued from products sold and all charges incurred by
          CollNect due to said declines. Notice of any applicable billing
          discrepancy on the part of the consumer, giving rise to a consumer
          charge-back by the credit card provider through REG-Z, must be first
          given to CollNect by the Customer prior to any initiation of a charge
          back in order to have reasonable time investigate the nature of the
          Customer&rsquo;s claim, billing and the charge.&nbsp;
        </p>
        <p>
          <strong>
            <u>Taxes</u>
          </strong>
          . Any applicable sales, or other taxes, fees or charges imposed on
          CollNect as a result of sale of Services shall incorporate the Sales
          Tax for the State of Georgia, or the Customer&rsquo;s billing address
          state&rsquo;s sales tax, when applicable, and will be added to
          Customer&rsquo;s account or invoice when imposed or required by
          law.&nbsp; CollNect&rsquo;s Services as deemed as PaaS or
          Infrastructure as a Service within a&nbsp; virtual environment for
          purposes of this License.
        </p>
        <p>
          <strong>
            <u>Use by Customers outside the United States</u>
          </strong>
          .&nbsp; CollNect does not presently offer or support the Service in
          any countries other than the United States. If you use the PaaS from
          outside of the United States to contact parties , you will be solely
          responsible for any violations of local laws and regulations resulting
          from such use, and CollNect has the right to terminate your account
          and PaaS services. Again, we reserve this right to terminate your
          Service immediately if we determine, in our sole and absolute
          discretion, that you have used the Service from outside of the United
          States to contact persons within the United States.
        </p>
        <p>
          <strong>
            <u>Export Control Laws</u>
          </strong>
          . The export and re-export of Materials of this website and/or
          platform are controlled by the export laws and regulations of the
          United States, as they may be amended from time to time. Accordingly,
          you certify that you are aware of and will comply with all applicable
          export rules and regulations, including the responsibility to obtain a
          license for the export or re-export of the &ldquo;Materials&rdquo; to
          any destination requiring such a license. In addition, the Materials
          may not be exported or re-exported to{' '}
          <strong>
            Cuba, Iran, Iraq, Libya, Sudan, the Taliban-controlled regions of
            Afghanista
          </strong>
          n or to any other country to which the United States prohibits the
          export of goods, technology or services to nationals of those
          countries, wherever located. Moreover, Materials may be distributed to
          persons on the Table of Denial Orders, the Entity List, or the List of
          Specially Designated Nationals maintained by the U.S. government. By
          transacting through this website, you are certifying that you are not
          a national of one of the above-listed countries or of any other
          country to which the United States embargoes goods, services or
          technology and that you are not a person on the Table of Denial
          Orders, the Entity List, or the List of Specially Designated
          Nationals.
        </p>
        <p>
          <strong>
            <u>Account Number and Password Security</u>
          </strong>
          . You are solely responsible for maintaining the confidentiality and
          security of your account number and/or password used to purchase
          Services through this website, your account and the PaaS platform. You
          are responsible for all uses of your account, whether or not actually
          or expressly authorized by you.&nbsp; Any unauthorized access of the
          Customer&rsquo;s account that is the result of the Customer&rsquo;s
          negligent handling of any account number or password is expressly the
          liability of the Customer.&nbsp; The Customer expressly waives all
          legal defenses and relief against CollNect for any collectable debt
          that arises from the unauthorized access of their services, by and
          through their account, which is a result of their own mishandling,
          negligence, or intentional disclosure of their account or password.
        </p>
        <p>
          <strong>
            <u>No Warranties on Service or Software</u>
          </strong>
          <strong>.</strong> CollNect DOES NOT MAKE ANY WARRANTIES OF ANY KIND,
          EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF
          MERCHANTIBILITY OR FITNESS FOR A PARTICULAR PURPOSE WITH REGARD TO THE
          SOFTWARE, THE PLATFORM &nbsp;OR ITS FUNCTION. OR ANY TEMPLATE OR
          PRESET FUNCTION OF SOFTWARE. &nbsp;COLLNECT SHALL NOT BE LIABLE FOR
          ANY COST OR DAMAGE ARISING EITHER DIRECTLY OR INDIRECTLY FROM ANY SUCH
          TRANSACTION OR USE OF THE PAAS. COLLNECT DOES NOT WARRANT THAT THE
          SOFTWARE OR PLATFORM WILL BE ERROR-FREE OR WILL OPERATE WITHOUT
          FAILURE OR INTERRUPTION NOR THAT ANY DEFECTS IN THE SOFTWARE OR ITS
          FUNCTION WILL BE CORRECTED UNDER ANY WARRANTY.
        </p>
        <p>
          <strong>
            <u>Expressed Limitation of Liability</u>
          </strong>
          <strong>.</strong> IN NO EVENT WILL COLLNECT BE LIABLE TO THE CUSTOMER
          FOR (I) ANY INCIDENTAL, CONSEQUENTIAL, OR INDIRECT DAMAGES (INCLUDING,
          BUT NOT LIMITED TO DAMAGES FOR LOSS INTERRUPTION, OR LOSS OF PROPERTY,
          DATA AND THE LIKE) ARISING OUT OF THE USE OF OR INABILITY TO USE THE
          SERVICES AND MATERIALS SOLD THROUGH THIS WEBSITE OR THE PLATFORM, EVEN
          IF COLLNECT OR ITS AUTHORIZED REPRESENTATIVES HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES, OR (II) ANY CLAIM ATTRIBUTABLE TO ERRORS,
          OMISSIONS, OR OTHER INACCURACIES IN THE SERVICES AND/OR MATERIALS SOLD
          THROUGH THIS WEBSITE OR THE PLATFORM.&nbsp; THIS INCLUDES ANY PRESET
          TEMPLATES OR FUNCTIONS PROVIDED BY COLLNECT AS COURTESY TO CUSTOMER.
        </p>
        <p>
          <strong>
            <u>Indemnification</u>
          </strong>
          <strong>.</strong> The Customer agrees to indemnify, defend and hold
          CollNect, its officers, directors, employees, agents, shareholders,
          licensors, suppliers, business partners and any beneficial owners from
          and against all losses, expenses, damages and costs, including
          reasonable attorneys&rsquo; fees, resulting from Customer&rsquo;s use
          of the PaaS, including, without limitation any violation of TCPA Laws
          and Rules, DNC Laws, or any obligation or agreement of these Terms and
          Conditions by Customer. The provisions of this paragraph are for the
          benefit of CollNect and its officers, directors, employees, agents,
          shareholders, licensors, suppliers, business partners and beneficial
          owners regarding the Services sold through the platform. Each of these
          individuals or entities shall have the right to assert and enforce
          these provisions directly against Customer on its own
          behalf.&nbsp;&nbsp; In the event of litigation due to violation of
          TCPA Laws and Rules or DNC Laws or similar statutory violations, the
          Customer will hold CollNect harmless from any final or stipulated
          judgment of damages and attorney fees, and either defend CollNect at
          its own cost or reimbursed CollNect&rsquo;s cost of defense on a
          monthly basis.&nbsp; Customer agrees that CollNect may use injunctive
          relief to enforce this clause and agrees no bond shall be required,
          CollNect&rsquo;s attorney&rsquo;s fees and costs shall be recovered as
          a part of the injunction and that it consents to a Bench Trial for
          purposes of this clause.
        </p>
        <p>
          <strong>
            <u>No Warranties on Public Web Site</u>
          </strong>
          <strong>.</strong> HYPERLINKS OR OTHER WEB PAGES ACCESSIBLE FROM THE
          HOME PAGE WEBSITE OF COLLNECT MAY ENABLE YOU TO LEAVE COLLNECT&rsquo;S
          SITE. THE LINKED SITES ARE NOT UNDER THE CONTROL OF COLLNECT AND
          COLLNECT IS NOT RESPONSIBLE FOR THE CONTENTS OF ANY LINKED SITE OR ANY
          LINK CONTAINED IN A LINKED SITE, OR ANY CHANGES OR UPDATES TO SUCH
          SITES. COLLNECT IS PROVIDING THESE LINKS TO YOU ONLY AS A CONVENIENCE,
          AND THE INCLUSION OF ANY LINK DOES NOT IMPLY ENDORSEMENT BY COLLNECT
          ON ITS PUBLIC WEBSITE. COLLNECT AND ITS AFFILIATES DO NOT CONTROL OR
          ENDORSE THE CONTENT OF THIRD-PARTY WEBSITES. ALL SERVICES AND
          MATERIALS PROVIDED ON THE COLLNECT SITE ARE PROVIDED &ldquo;AS
          IS&ldquo;. COLLNECT DOES NOT GUARANTEE OR WARRANT THAT THE FILES
          AVAILABLE FOR DOWNLOADING FROM THE PUBLIC WEBSITE WILL BE FREE OF
          INFECTION OR VIRUSES, WORMS, TROJAN HORSES OR OTHER CODE THAT CONTAINS
          CONTAMINATING OR DESTRUCTIVE PROPERTIES. YOU ARE RESPONSIBLE AND ARE
          URGED TO IMPLEMENT SUFFICIENT PROCEDURES AND CHECKPOINTS TO SATISFY
          YOUR PARTICULAR REQUIREMENTS FOR ACCURACY OF DATA INPUT AND OUTPUT,
          AND FOR MAINTAINING A MEANS EXTERNAL TO THE SITE FOR THE
          RECONSTRUCTION OF ANY DATA LOSS. YOU ASSUME TOTAL RESPONSIBILITY AND
          RISK FOR YOUR USE OF THE WEBSITE AND THE INTERNET.
        </p>
        <p>
          <strong>
            <u>Trademarks</u>
          </strong>
          . All corporate names, service marks, logos, trade names, trademarks,
          websites and domain names of CollNect (collectively
          &ldquo;Marks&ldquo;) are and shall remain the exclusive property of
          CollNect and nothing in this agreement shall grant Customer or any
          Third party the license to use such Marks.&nbsp; All service marks and
          trademarks of manufactures are and shall remain exclusively their own,
          and their appearance on this website shall not be construed in any way
          to grant or vest the Customer or any visitor, a license or right to
          copy or use such marks from this website. Such marks are used only to
          offer and sell the Products of the manufactures.
        </p>
        <p>
          <strong>
            <u>Copyright</u>
          </strong>
          . All Content and Materials found at this website, or within the
          Platform Account, are protected under U.S. Copyright, or, by and
          through other intellectual property laws. Any commercial use of the
          Services Software, and Materials found on the Website or CollNect
          platform account is strictly prohibited, without the express, prior,
          written consent of CollNect. Any reproduction or redistribution of the
          Services and/or Materials not in accordance with the terms set forth
          herein is expressly prohibited by law and may result in severe civil
          and criminal penalties.
        </p>
        <p>
          <strong>
            <u>No Waiver</u>
          </strong>
          <strong>. </strong>The failure of Collnect at any time to require
          performance by you of any provision of these Terms and Conditions
          shall not affect in any way the full right to require the performance
          at any subsequent time. Collnect, in its single discretion, may modify
          these Terms and conditions.
        </p>
        <p>
          <strong>
            <u>Severability</u>
          </strong>
          <strong>.&nbsp; </strong>These terms and conditions are binding on you
          and Collnect as well as for your representatives and heirs and those
          of Collnect, respectively. If a court determines that any part or
          provision of these Terms and Conditions is null or unenforceable under
          applicable law, that part or provision shall be null and void only
          with the scope such nullity or unenforceability, without affecting in
          any way the remaining parts or provisions of these Terms and
          Conditions, which shall continue in full force and effect, and the you
          and CollNect shall substitute for the invalid provisions a valid
          provision which most closely approximates the intent and economic
          effect of the invalid provision.
        </p>
        <p>
          <strong>
            <u>Customer Dispute Resolution</u>
          </strong>
          <strong>.&nbsp; </strong>This section provides a resolution of
          disputes through arbitration rather than court proceedings, judgments
          or class actions. The arbitration shall be final and obligatory. If
          you have any conflict related to your service, please first contact
          the customer service number listed on the CollNect public website or
          within your account. You agree that any conflict arising out of or
          relating to these terms (regardless of whether the dispute is based on
          contracts, wrongdoing, laws, frauds, false statements or any other
          legal or equitable theory) shall be submitted to the Arbitration
          Association of the United States (&ldquo;AAA&ldquo;) for final and
          binding arbitration. The arbitration shall be conducted in accordance
          with the AAA Arbitration Rules for Commercial Arbitration Rules for
          the Settlement of Consumer Disputes (&ldquo;AAA Rules&ldquo;), as such
          rules are in effect of the date of commencement of the arbitration and
          as they are modified by the then-pertinent Limited License and its
          Terms and Conditions. The arbitration should be determinate by single
          arbitrator selected form the AAA neutral panel. For more information
          on AAA, visit the website at{' '}
          <a href="http://www.adr.org">www.adr.org</a> . The arbitration shall
          be based only on written submissions of the involved parts and
          documents submitted to the arbitrator, unless the involved parts agree
          or the arbitrator orders otherwise. Additional charges may apply for
          these procedures. The award may be confirmed and enforced in any
          competent court of jurisdiction. Currently, the AAA Rules provide
          reduced court fees for consumers. Unless otherwise provided in the AAA
          Rules or in the arbitration award, all other administrative fees and
          expenses, including expenses and fees of the arbitrator, shall be
          shared equally between you and Collnect. Each party shall accept the
          expense incurred in the preparation and presentation of its own cause.
          All in-person arbitration procedures shall be conducted in Lewes,
          Delaware or at the location selected by AAA, unless otherwise agreed
          by the parties. The law governing the interpretation of these terms
          and conditions shall be the laws of the State of Georgia. Venue shall
          be Fulton County, Georgia. Each dispute will be submitted to
          arbitration individually and will not be consolidated in any action
          with the disputes or claims of other consumers. No dispute or claim
          may be brought as a class action or as a private general attorney and
          you shall not have the right to act as a class representative or to
          participate as a member of a class of claimants regarding any dispute
          or claim linked to the service. Any dispute or claim arising out of
          these terms or relating to these terms shall be brought within one
          year after the date on which the basis for the dispute or claim arises
          or within the period set forth by law. Mediation through AAA is
          mandatory, for any customer claim, before any other formal arbitration
          presented by any of the involved parts. CollNect reserves the
          exclusive and sole right to litigate any claim due to nonpayment or
          chargeback of a credit or debit card in an appropriate jurisdiction
          court and the user accepts the personal jurisdiction of this court for
          such claims or causes.
        </p>
        <div>
          <p>
            <strong>
              □ I Accept the Terms &amp; Conditions of Limited License
            </strong>
          </p>
        </div>
      </div>
      <Footer history={history} />
    </div>
  );
};

Term.propTypes = {
  history: PropTypes.any,
};

export default Term;
