import React from 'react';
import { Container } from 'reactstrap';
import './styles.scss';
import { Button, PhoneInput, TextInput } from 'components';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';
import Normalizer from 'helpers/normalizer';
import PropTypes from 'prop-types';
import Routes from 'routes/routes';

const selector = formValueSelector('RELOAD_CARD_FORM');

const ReloadCardCustomerForm = ({ history, handleSubmit, location }) => {
  const phone = useSelector((state) => selector(state, 'phone'));
  const name = useSelector((state) => selector(state, 'name'));

  const searchParams = new URLSearchParams(location.search);

  const onSubmit = () => {
    history.push({
      pathname: Routes.SCAN_BAR_CODE,
      search: `?retailer=${searchParams.get('retailer')}&phone=${phone}&name=${
        name ?? ''
      }`,
    });
  };

  return (
    <div className="omny-reloadCardCustomerForm">
      <Container>
        <p>Welcome to</p>
        <h6 className="omny-reloadCardCustomerForm__storeName">
          {searchParams?.get('business_name')}
        </h6>
        <p className="omny-reloadCardCustomerForm__desc">
          Please fill all your information and waiting from Cashier
        </p>
        <div className="omny-reloadCardCustomerForm__form">
          <Field
            name="phone"
            component={PhoneInput}
            label="Enter Customer's Phone Number"
            normalize={Normalizer.normalizePhone}
          />
          <Field name="name" component={TextInput} label="Name (optional)" />
          <div className="d-grid">
            <Button
              color="primary"
              disabled={!phone}
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

ReloadCardCustomerForm.propTypes = {
  handleSubmit: PropTypes.func,
  history: PropTypes.any,
  location: PropTypes.any,
};

export default reduxForm({
  form: 'RELOAD_CARD_FORM',
})(ReloadCardCustomerForm);
