import { takeEvery, put } from 'redux-saga/effects';
import { getPhoneRegisterSuccess, updateRegisterInfoSuccess } from './actions';
import {
  GET_PHONE_REGISTER_REQUESTED,
  UPDATE_REGISTER_INFO_REQUESTED,
} from './actionTypes';

function* storeRegisterInfo(action) {
  yield put(updateRegisterInfoSuccess(action.payload));
}

function* storePhoneRegisterInfo(action) {
  yield put(getPhoneRegisterSuccess(action.payload));
}

function* RegisterSaga() {
  yield takeEvery(UPDATE_REGISTER_INFO_REQUESTED, storeRegisterInfo);
  yield takeEvery(GET_PHONE_REGISTER_REQUESTED, storePhoneRegisterInfo);
}

export default RegisterSaga;
