import React from 'react';
import PropTypes from 'prop-types';
import BannerWhy from 'assets/img/banner-why.png';
import { PageHeader, Footer } from 'components';
import './styles.scss';
import Routes from 'routes/routes';

const WelcomeDesktop = ({ history }) => {
  const redirectToRegister = () => {
    history.push(Routes.REGISTER_CONTACT);
  };
  return (
    <div className="welcome-desktop">
      <PageHeader history={history} disableSignup />
      <section className="welcome">
        <div className="bg_opacity">
          <div className="container">
            <div className="row welcome-content">
              <div className="col-md-6 animate__animated animate__fadeInUp">
                <img
                  id=""
                  className="img-center img-fluid "
                  src={BannerWhy}
                  alt=""
                />
              </div>
              <div className="col-md-6 animate__animated animate__fadeInUp">
                <h2 className="rt-title-heading">
                  <span className="letters">Welcome to you</span>
                </h2>
                <p className="d-flex">
                  <span>
                    To complete the registration process, you need to prepare
                    the following information and documents:
                  </span>
                </p>
                <ul>
                  <li>Business license</li>
                  <li>Your Driver license</li>
                  <li>ID tax</li>
                  <li>Photo of Voiced Check</li>
                </ul>
                <p className="d-flex">
                  <button
                    type="button"
                    className="btn btn-danger btn-lg button-size"
                    onClick={redirectToRegister}
                  >
                    Let&lsquo;s Start
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer onlyFooter />
    </div>
  );
};

WelcomeDesktop.propTypes = {
  history: PropTypes.any,
};

export default WelcomeDesktop;
