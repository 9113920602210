import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import './styles.scss';

const OutlinedButton = ({ children, disabled, onClick }) => {
  return (
    <Button
      color="primary btn-lg omny-outlinedButton"
      disabled={disabled}
      onClick={onClick}
      outline
    >
      {children}
    </Button>
  );
};

OutlinedButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default OutlinedButton;
