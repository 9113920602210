import React from 'react';
import CheckCircleIcon from './check-circle.svg';
import moment from 'moment/moment';
import PropTypes from 'prop-types';

const PreOrderHeader = ({ date, seq }) => {
  return (
    <div>
      <div className="omny-reloadCardResult__header">
        <img src={CheckCircleIcon} alt="check-circle-icon" />
        <p>Pre-Order OMNY card successful</p>
      </div>
      <div className="omny-reloadCardResult__orderView">
        <p>Your Order ID</p>
        <h2>{seq}</h2>
        <p>{moment(date).format('MM/DD/YYYY HH:mm')}</p>
      </div>
    </div>
  );
};

PreOrderHeader.propTypes = {
  date: PropTypes.string,
  seq: PropTypes.number,
};

export default PreOrderHeader;
