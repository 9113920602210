import { Button } from 'components';
import React from 'react';
import { Container } from 'reactstrap';
import CircleInfoIcon from './circle-info.svg';
import './styles.scss';
import Routes from 'routes/routes';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateRegisterInfoRequested } from 'store/actions';

const RegisterWaitingForApproval = ({ history }) => {
  const dispatch = useDispatch();
  const onCreateRegister = () => {
    dispatch(updateRegisterInfoRequested());
    history.replace(Routes.REGISTER_WELCOME);
  };
  return (
    <div className="omny-registerStatus">
      <Container>
        <div>
          <div className="text-center">
            <img src={CircleInfoIcon} alt="circle-checkbox-icon" />
          </div>
          <p className="text-center mt-4">
            Your register with phone number in review. Please waiting in 24
            hours for feedback via email.
          </p>
          <p className="text-center">
            Contact accounting if you have any question:
            <br /> 877-777-0388
          </p>
        </div>
        <div className="d-grid mt-5 justify-content-center">
          <Button color="danger button-size" onClick={onCreateRegister}>
            Close
          </Button>
        </div>
      </Container>
    </div>
  );
};

RegisterWaitingForApproval.propTypes = {
  history: PropTypes.any,
};

export default RegisterWaitingForApproval;
