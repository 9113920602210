import { Button } from 'components';
import React from 'react';
import { Container } from 'reactstrap';
import CircleWarningIcon from './circle-warning.svg';
import './styles.scss';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateRegisterInfoRequested } from 'store/actions';
import Routes from 'routes/routes';

const ManyOTPRequest = ({ history }) => {
  const dispatch = useDispatch();

  const onContinueRegister = () => {
    dispatch(updateRegisterInfoRequested());
    history.replace(Routes.REGISTER_WELCOME);
  };

  return (
    <div className="omny-registerStatus">
      <Container>
        <div>
          <div className="text-center">
            <img src={CircleWarningIcon} alt="circle-checkbox-icon" />
          </div>
          <p className="text-center mt-4">
            You have requested to send OTP too many times, please contact
            customer support for assistance.
          </p>
        </div>
        <div className="d-grid mt-5 justify-content-center">
          <Button color="danger button-size" onClick={onContinueRegister}>
            Back to home
          </Button>
        </div>
      </Container>
    </div>
  );
};

ManyOTPRequest.propTypes = {
  history: PropTypes.any,
};

export default ManyOTPRequest;
