import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'reactstrap';
import './styles.scss';

const PrimaryButton = ({ children, disabled, onClick, color, loading }) => {
  return (
    <Button
      color={`${color || 'primary'} btn-lg`}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? (
        <Spinner color="secondary" size="sm" type="grow">
          Loading...
        </Spinner>
      ) : (
        children
      )}
    </Button>
  );
};

PrimaryButton.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PrimaryButton;
