import { Button } from 'components';
import React from 'react';
import { Container } from 'reactstrap';
import CircleWarningcon from './circle-warning.svg';
import './styles.scss';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Routes from 'routes/routes';

const RegisterActivated = ({ history }) => {
  const { registerInfo } = useSelector((state) => state.app.Register);

  const onCreateRegister = () => {
    history.replace(Routes.REGISTER_BUSINESS.replace(':id', registerInfo.id));
  };

  return (
    <div className="omny-registerStatus">
      <Container>
        <div>
          <div className="text-center">
            <img src={CircleWarningcon} alt="circle-checkbox-icon" />
          </div>
          <p className="text-center mt-4">
            Your register with phone number was rejected. Please resolve error
            to complete register account.
          </p>
        </div>
        <div className="d-grid mt-5 justify-content-center">
          <Button color="danger button-size" onClick={onCreateRegister}>
            Resolve error
          </Button>
        </div>
      </Container>
    </div>
  );
};

RegisterActivated.propTypes = {
  history: PropTypes.any,
};

export default RegisterActivated;
