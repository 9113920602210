export const REGISTER_STEP = {
  step1: 1,
  step1_phone_verifying: 1,
  step2: 2,
  step3: 3,
  step3_term: 3,
  step3_submit: 3,
};

export const REGISTER_STATUS = {
  new: 'new',
  approved: 'approved',
  activated: 'activated',
  rejected: 'rejected',
  waiting_for_approval: 'waiting_for_approval',
};

export const SERVICECONSTANTS = {
  accountingPhoneNumber: '1-888-755-3535',
  csPhoneNumber: '1-877-777-0314',
};
