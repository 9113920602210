import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { TextInput } from 'components';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateRegisterInfoRequested } from 'store/actions';
import { useSelector } from 'react-redux';
import * as url from 'helpers/urlHelper';
import ApiHelper from 'helpers/apiHelper';
import { useParams } from 'react-router-dom';
import Normalizer from 'helpers/normalizer';
import Validator from 'helpers/validator';
import Routes from 'routes/routes';

const ActivateRetailerAccount = ({
  history,
  handleSubmit,
  change,
  initialize,
}) => {
  const dispatch = useDispatch();
  const { id: registerId } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const registerInfo = useSelector((state) => state.app.Register.registerInfo);

  useEffect(() => {
    if (registerId && !registerInfo) {
      getRegisterInfo();
    } else {
      initialize({
        phone: Normalizer.formatSecurePhoneNumber(registerInfo.phone),
      });
    }
  }, [registerId]);

  const getRegisterInfo = async () => {
    try {
      const res = await ApiHelper.get(
        url.GET_REGISTER_INFO.replace('{registerId}', registerId),
      );
      dispatch(updateRegisterInfoRequested(res));
      change('phone', res?.phone);
    } catch (error) {}
  };

  const onSubmit = async (value) => {
    try {
      setSubmitting(true);
      const res = await ApiHelper.post(url.POST_REGISTER_ACTIVATE, {
        username: registerInfo.phone,
        password: value.password,
      });

      if (res) {
        return history.replace(
          Routes.REGISTER_ACTIVATED.replace(':id', registerId),
        );
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="omny-activated-account">
      <Container>
        <div className="row">
          <div className="col-12">
            <Field
              component={TextInput}
              name="phone"
              label="Phone number"
              normalize={Normalizer.formatSecurePhoneNumber}
              disabled
            />
          </div>
          <div className="col-12">
            <Field
              component={TextInput}
              name="password"
              type="password"
              label="New password"
              validate={[Validator.isValidPassword]}
            />
          </div>
          <div className="col-12">
            <Field
              component={TextInput}
              type="password"
              name="confirmPassword"
              label="Confirm password"
              validate={[Validator.isPasswordConfirmed]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="devider"></hr>
          </div>
        </div>
        <div className="d-grid flex-end">
          <Button
            color="danger button-size"
            onClick={handleSubmit(onSubmit)}
            loading={submitting}
          >
            Acitvated account
          </Button>
        </div>
      </Container>
    </div>
  );
};

ActivateRetailerAccount.propTypes = {
  history: PropTypes.any,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  change: PropTypes.func,
};

export default reduxForm({
  form: 'RESET_PASSWORD_FORM',
})(ActivateRetailerAccount);
