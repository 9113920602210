import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CustomerItem = ({ label, value, className }) => {
  const classNames = classnames('omny-customerInfo__item', className);
  return (
    <div className={classNames}>
      <p className="omny-customerInfo__itemLabel">{label}</p>
      <h4 className="omny-customerInfo__itemValue">{value}</h4>
    </div>
  );
};

CustomerItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default CustomerItem;
