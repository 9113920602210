import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import ArrowBackIcon from './arrow-back.svg';
import { useHistory } from 'react-router-dom';

const Header = ({ title }) => {
  const history = useHistory();
  return (
    <div className="omny-header">
      <a
        className="omny-header__iconBack"
        href="#"
        onClick={() => history.goBack()}
      >
        <img src={ArrowBackIcon} alt="arrow-back-icon" />
      </a>
      <div className="omny-header__title">{title}</div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
