import React, { useRef, useState } from 'react';
import { Spinner } from 'reactstrap';
import CarmeraIcon from './camera.svg';
import CarmeraWhiteIcon from './camera-white.svg';

import PropTypes from 'prop-types';
import * as url from 'helpers/urlHelper';
import Config from 'config';
import './styles.scss';

const UploadDocument = ({
  label,
  name,
  input,
  errorMessage,
  meta: { error, touched, warning },
}) => {
  const inputFile = useRef();
  const [loading, setLoading] = useState(false);
  const onUploadPhoto = () => {
    inputFile.current.click();
  };

  const onUploadToServer = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await fetch(Config.API_URL + url.UPLOAD_REGISTER_IMAGE, {
        method: 'POST',
        body: formData,
      });

      if (response.status === 200 || response.status === 201) {
        const results = await response.json();
        if (results?.image) {
          input.onChange(results?.image);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onSelectImage = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    onUploadToServer(file);
  };

  const styles = {
    '--photoBackgroundImage': `url(${input.value})`,
  };

  return (
    <div className="omny-uploadDocument">
      <button
        onClick={onUploadPhoto}
        className="omny-uploadDocument__photoButton"
      >
        {input.value ? (
          <div className="omny-uploadDocument__photoContent" style={styles}>
            <img src={CarmeraWhiteIcon} alt="camera-icon" />
            <div className="omny-uploadDocument__photoContent__overlay" />
          </div>
        ) : loading ? (
          <Spinner>Loading...</Spinner>
        ) : (
          <img src={CarmeraIcon} alt="camera-icon" />
        )}
        <input
          type="file"
          id={name}
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={onSelectImage}
        />
      </button>
      {label && <p className="mt-2">{label}</p>}
      {errorMessage ? (
        <div className="text-danger mt-2">{errorMessage}</div>
      ) : (
        touched &&
        ((error && <div className="text-danger mt-2">{error}</div>) ||
          (warning && <div className="text-warning mt-2">{warning}</div>))
      )}
    </div>
  );
};

UploadDocument.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  input: PropTypes.any,
  meta: PropTypes.any,
  errorMessage: PropTypes.string,
};

export default UploadDocument;
