//AUTH
export const POST_REFERSH_TOKEN = '/business-api/auth/token/refresh';

//PRODUCT

export const GET_PRODUCT = '/product-api/product';
export const POST_PRE_PRDER = '/public-api/pre-orders';
export const GET_PRE_ORDER = '/public-api/pre-orders/{id}';
export const GET_REGISTER_BY_PHONE = '/business-api/auth/register';
export const INIT_REGISTER = '/business-api/auth/register';
export const POST_REGISTER_CONTACT_INFO =
  '/business-api/auth/register/{registerId}/1';
export const POST_REGISTER_BUSINESS_INFO =
  '/business-api/auth/register/{registerId}/2';

export const POST_REGISTER_BANK_INFO =
  '/business-api/auth/register/{registerId}/3';

export const POST_REGISTER_VERIFICATION_CODE =
  '/business-api/auth/register/{registerId}/verify';

export const POST_REGISTER_VERIFICATION_SALE_AGENT_CODE =
  '/business-api/auth/register/{registerId}/sale-agent-verify';

export const POST_REGISTER_APPLICATION =
  '/business-api/auth/register/{registerId}/submit';

export const GET_REGISTER_INFO = '/business-api/auth/register/{registerId}';
export const GET_ADDRESS_BY_ZIP_CODE = '/business-api/retailer/zip-code-lookup';
export const GET_SALE_AGENT = '/business-api/auth/register/sale-agent-codes';
export const UPLOAD_REGISTER_IMAGE = '/business-api/auth/register/upload-image';
export const POST_REGISTER_RESEND_SMS =
  '/business-api/auth/register/{registerId}/resend-sms';

export const POST_REGISTER_SALE_AGENT_RESEND_SMS =
  '/business-api/auth/register/{registerId}/sale-agent-resend-sms';

export const POST_REGISTER_ACTIVATE = '/business-api/auth/activate';

// /business-api/auth/register/$registerId/resend-sms
