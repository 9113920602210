import React from 'react';
import BulletPoint from 'assets/img/bullet-point.png';
import BannerHand from 'assets/img/banner-hand.png';
import BannerReloadCard from 'assets/img/banner-reload-card.png';
import HowItWork1 from 'assets/img/howitwork_1.png';
import BannerWhy from 'assets/img/banner-why.png';
import GGPlay from 'assets/img/ggplay.png';
import Appstore from 'assets/img/app-store.png';
import PhoneCheck from 'assets/img/illus-check.png';
import IMGSupport from 'assets/img/support.png';
import PropTypes from 'prop-types';
import { Footer, PageHeader } from 'components';
import Routes from 'routes/routes';

import './styles.scss';

const Dashboard = ({ history }) => {
  const redirectToRegister = () => {
    history.push(Routes.REGISTER_WELCOME);
  };
  return (
    <div className="omny-dashboard">
      <PageHeader history={history} />
      <section className="hero-5 bg-light elementor-column" id="home">
        <div className="container">
          <div className="row elementor-container elementor-column-gap-default">
            <div className="elementor-column col-md-6">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="rt-hover-heading ">
                  <h1 className="rt-title-heading animate__animated animate__fadeInLeft">
                    Add OMNY to
                    <br />
                    <span className="highlight-after-text animate__animated animate__fadeInLeft">
                      your business today
                    </span>
                  </h1>
                </div>
                <div className="elementor-element elementor-widget-text-editor">
                  <div className="row content-banner">
                    <div className="col-lg-12 col-md-12 animate__animated animate__fadeInUp">
                      <span className="icon ">
                        <img
                          id="banner-image"
                          className="img-center"
                          src={BulletPoint}
                          alt=""
                        />
                      </span>
                      <div className="content-box ">
                        <span>More foot traffic to your store</span>
                      </div>
                    </div>
                    <div className="col-lg-12  col-md-12 animate__animated animate__fadeInUp">
                      <span className="icon ">
                        <img
                          id="banner-image"
                          className="img-center"
                          src={BulletPoint}
                          alt=""
                        />
                      </span>
                      <div className="content-box">
                        <span>New product for your business</span>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 animate__animated animate__fadeInUp">
                      <span className="icon">
                        <img
                          id="banner-image"
                          className="img-center"
                          src={BulletPoint}
                          alt=""
                        />
                      </span>
                      <div className="content-box">
                        <span>No investment cost at all</span>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 animate__animated animate__fadeInUp">
                      <span className="icon ">
                        <img
                          id="banner-image"
                          className="img-center"
                          src={BulletPoint}
                          alt=""
                        />
                      </span>
                      <div className="content-box ">
                        <span>Generate extra money</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rt-arrow-btn animate__animated animate__fadeInUp">
                  <button
                    type="button"
                    className="btn btn-danger btn-lg button-size"
                    onClick={redirectToRegister}
                  >
                    Sign Up Free
                  </button>
                </div>
              </div>
            </div>
            <div className="elementor-column col-md-6 animate__animated animate__fadeInRight">
              <img
                id="banner-image"
                className="img-center animation-zoom img-fluid"
                src={BannerHand}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section id="aboutus">
        <div className="container">
          <div className="row">
            <div className="col-md-6 animate__animated animate__fadeInUp">
              <img
                id=""
                className="img-center img-fluid "
                src={BannerReloadCard}
                alt=""
              />
            </div>
            <div className="col-md-6 content-aboutus animate__animated animate__fadeInUp">
              <h2 className="rt-title-heading">
                What is <span className="letters">OMNY?</span>
              </h2>
              <div className="elementor-widget-container">
                <b>OMNY</b> is the contactless fare payment system for public
                transportation in the New York region.
                <br />
                <br />
                Customers can now buy and reload an <b>OMNY</b> card at your
                store location. Your store location(s) will be listed within the{' '}
                <b>OMNY</b> retail locator and customers can enter their
                location to find a store nearest them.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="howitwork">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 animate__animated animate__fadeInUp">
              <h2 className="rt-title-heading">
                How Reloading <span className="letters">OMNY</span> Works
              </h2>
              <div className="elementor-widget-container">
                Your customers can now reload an <b>OMNY</b> card at your store
                or stores across the New York region. There is little work
                required from you or the store cashier besides assisting and
                handling the payment processing
              </div>
              <h3>
                <b>via QR Code</b>
              </h3>
              <div className="row text-center">
                <div className="col-sm-4 align-items-center">
                  <p className="howitwork-title">STEP 1</p>
                  <p className="howitwork-content">
                    Customer scans a QR code displayed in your store
                  </p>
                </div>
                <div className="col-sm-4 align-items-center">
                  <p className="howitwork-title">STEP 2</p>
                  <p className="howitwork-content">
                    The customers fills out the required information
                  </p>
                </div>
                <div className="col-sm-4 align-items-center">
                  <p className="howitwork-title">STEP 3</p>
                  <p className="howitwork-content">
                    The store cashier collect the payment
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 animate__animated animate__fadeInUp">
              <img
                id=""
                className="img-center img-fluid "
                src={HowItWork1}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section id="why">
        <div className="bg_opacity">
          <div className="container">
            <div className="row why-content">
              <div className="col-md-6 animate__animated animate__fadeInUp">
                <img
                  id=""
                  className="img-center img-fluid "
                  src={BannerWhy}
                  alt=""
                />
              </div>
              <div className="col-md-6 animate__animated animate__fadeInUp">
                <h2 className="rt-title-heading">
                  Reasons Why You Should
                  <br />
                  Add <span className="letters">OMNY</span> To Your Business
                </h2>
                <p className="d-flex">
                  <span className="avatar avatar-lg bg-red rounded-circle shadow-primary">
                    1
                  </span>
                  <span className="ms-4">
                    If you’re looking for new and profitable product/service to
                    add to your business
                  </span>
                </p>
                <p className="bg_blue d-flex">
                  <span className="avatar avatar-lg bg-info rounded-circle shadow-primary">
                    2
                  </span>
                  <span className="ms-4">
                    If you want to grow your business with extra income
                  </span>
                </p>
                <p className="d-flex">
                  <span className="avatar avatar-lg bg-success rounded-circle shadow-primary">
                    3
                  </span>
                  <span className="ms-4">
                    If you’re looking for more foot traffic to your store
                  </span>
                </p>
                <p className="bg_blue d-flex">
                  <span className="avatar avatar-lg bg-warning rounded-circle shadow-primary">
                    4
                  </span>
                  <span className="ms-4">
                    If you want brand exposure to a massive audience on the OMNY
                    network
                  </span>
                </p>
                <p className="d-flex">
                  <button
                    type="button"
                    className="btn btn-danger btn-lg button-size"
                    onClick={redirectToRegister}
                  >
                    Sign Up Free
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contactus">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 animate__animated animate__fadeInUp">
              <h2 className="rt-title-heading">
                Ready to grow your business?{' '}
                <span className="letters">Get started today!</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row text-center animate__animated animate__fadeInUp">
            <div className="col-md-4 align-items-center">
              <p className="contact-title">STEP 1</p>
              <h4>
                <b>Download the OMNY app</b>
              </h4>
              <a
                href="https://play.google.com/store/apps/details?id=com.collnect.omnyretailer"
                target="_blank"
                rel="noopener noreferrer"
                alt="chplay-omny"
              >
                <img
                  id=""
                  className="img-center img-fluid images-app"
                  src={GGPlay}
                  alt=""
                />
              </a>
              <a
                href="https://apps.apple.com/vn/app/omny-retailers/id1658819794"
                target="_blank"
                rel="noopener noreferrer"
                alt="appstore-omny"
              >
                <img
                  id=""
                  className="img-center img-fluid"
                  src={Appstore}
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-4 align-items-center">
              <p className="contact-title">STEP 2</p>
              <h4>
                <b>Complete the application on the app</b>
              </h4>
              <img id="" className="img-center " src={PhoneCheck} alt="" />
            </div>
            <div className="col-md-4 align-items-center">
              <p className="contact-title">STEP 3</p>
              <h4>
                <b>
                  Our team will review and notify you within 2-3 business days
                </b>
              </h4>
              <img id="" className="img-center " src={IMGSupport} alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer history={history} />
    </div>
  );
};

Dashboard.propTypes = {
  history: PropTypes.any,
};

export default Dashboard;
