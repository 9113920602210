import { Button } from 'components';
import React from 'react';
import { Container } from 'reactstrap';
import CircleCheckboxIcon from './circle-checkbox.svg';
import './styles.scss';
import Routes from 'routes/routes';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateRegisterInfoRequested } from 'store/actions';
import { SERVICECONSTANTS } from 'constants/common';

const RegisterConfirmation = ({ history }) => {
  const dispatch = useDispatch();
  const onCreateRegister = () => {
    dispatch(updateRegisterInfoRequested());
    history.replace(Routes.DASHBOARD);
  };
  return (
    <div className="omny-registerStatus">
      <Container>
        <div>
          <div className="text-center">
            <img src={CircleCheckboxIcon} alt="circle-checkbox-icon" />
          </div>
          <p className="text-center mt-4">
            Your registration has been submitted successfully.
          </p>
          <p>
            Your registration is being reviewed. Please check your email within
            24 hours or contact Accounting Support
            {SERVICECONSTANTS.accountingPhoneNumber}
          </p>
        </div>
        <div className="d-grid justify-content-center mt-5">
          <Button color="danger button-size" onClick={onCreateRegister}>
            Back to Home page
          </Button>
        </div>
      </Container>
    </div>
  );
};

RegisterConfirmation.propTypes = {
  history: PropTypes.any,
};

export default RegisterConfirmation;
