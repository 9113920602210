import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Validator from 'helpers/validator';
import UploadDocument from '../UploadDocument';
import { FormFieldMapping } from '../constants';

const BankPhotoDocument = ({ registerInfo, formValues }) => {
  const renderFieldError = (field) => {
    if (registerInfo && formValues) {
      const errorSnapshot = registerInfo.error_detail_snapshot;
      if (errorSnapshot) {
        return registerInfo[FormFieldMapping[field]] === formValues[field]
          ? errorSnapshot[FormFieldMapping[field]]
          : undefined;
      }
    }
    return undefined;
  };
  return (
    <div className="omny-bankPhotoDocument mt-4">
      <p className="fw-bold">Take photos documents</p>
      <p className="omny-bankPhotoDocument__desc">
        Please take photo of Voided check, Bank Letter, or Bank Statement
      </p>
      <div className="row mt-3">
        <div className="col-md-4 col-xs-12">
          <Field
            component={UploadDocument}
            name="bankImg"
            validate={[Validator.isRequired]}
            errorMessage={renderFieldError('bankImg')}
          />
        </div>
      </div>
    </div>
  );
};

BankPhotoDocument.propTypes = {
  registerInfo: PropTypes.object,
  formValues: PropTypes.object,
};

export default BankPhotoDocument;
