import { Button } from 'components';
import React from 'react';
import { Container } from 'reactstrap';
import CircleInfoIcon from './circle-info.svg';
import './styles.scss';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateRegisterInfoRequested } from 'store/actions';
import Routes from 'routes/routes';

const RegisterAlreadyInUse = ({ history }) => {
  const { registerInfo } = useSelector((state) => state.app.Register);
  const dispatch = useDispatch();

  const renderMessage = () => {
    if (registerInfo.status === 'approved') {
      if (!registerInfo.user_activated) {
        return (
          <p className="text-center mt-4">
            Your register with phone number was approved. Please activate your
            account to use our bussiness.
          </p>
        );
      }
      return (
        <p className="text-center mt-4">
          The phone number entered has already been used. Please use another
          phone number.
        </p>
      );
    }
    if (registerInfo.status === 'waiting_for_approval') {
      return (
        <>
          <p className="text-center mt-4">
            Your register with phone number in review. Please waiting in 24
            hours for feedback via email.
          </p>
          <p className="text-center">
            Contact accounting if you have any question:
            <br /> 877-777-0388
          </p>
        </>
      );
    }
    return '';
  };

  const onCloseRegister = () => {
    dispatch(updateRegisterInfoRequested());
    history.replace(Routes.REGISTER_WELCOME);
  };

  return (
    <div className="omny-registerStatus">
      <Container>
        <div>
          <div className="text-center">
            <img src={CircleInfoIcon} alt="circle-checkbox-icon" />
          </div>
          {renderMessage()}
        </div>
        <div className="d-grid mt-5 justify-content-center">
          <Button color="danger button-size" onClick={onCloseRegister}>
            Close
          </Button>
        </div>
      </Container>
    </div>
  );
};

RegisterAlreadyInUse.propTypes = {
  history: PropTypes.any,
};

export default RegisterAlreadyInUse;
