import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { CustomerInfo, TextInput, Button } from 'components';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';
import './styles.scss';
import PriceList from './PriceList';
import barCodeDark from '../../../assets/img/bar-code-dark.png';
import { v4 as uuidv4 } from 'uuid';
import Routes from 'routes/routes';

import ApiHelper from 'helpers/apiHelper';
import * as url from 'helpers/urlHelper';

const selector = formValueSelector('RELOAD_CARD_FORM');

const ReloadCardReloadForm = ({ handleSubmit, location, change, history }) => {
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [productError, setProductError] = useState(null);
  const amount = useSelector((state) => selector(state, 'amount'));
  const cardNumber = useSelector((state) => selector(state, 'cardNumber'));
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (!cardNumber) {
      const cardNumb = searchParams.get('code');
      change('cardNumber', cardNumb);
    }
  }, []);

  useEffect(() => {
    const fetchCardNumber = setTimeout(async () => {
      getProduct(cardNumber || '');
    }, 1000);

    return () => {
      clearTimeout(fetchCardNumber);
    };
  }, [cardNumber]);

  const getProduct = async (cardNumb) => {
    try {
      setFetching(true);
      const params = {
        filterCode: cardNumb,
        filterType: 'reload',
      };
      const response = await ApiHelper.get(url.GET_PRODUCT, {
        params,
      });
      if (
        response.min_length > cardNumb.length &&
        cardNumb.length < response.max_length
      ) {
        setProductError('Card number is invalid, please re-scan');
      } else {
        setProductError(null);
      }
      setProduct(response);
    } catch (error) {
    } finally {
      setFetching(false);
    }
  };

  const onSelectAmount = (amount) => {
    change('amount', amount);
  };

  const onReScan = () => {
    const phone = searchParams.get('phone');
    const name = searchParams.get('name');
    history.push({
      pathname: Routes.SCAN_BAR_CODE,
      search: `?retailer=${searchParams.get('retailer')}&phone=${phone}&name=${
        name ?? ''
      }`,
    });
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const cardNumber = searchParams.get('code').replace(product?.prefix, '');
      const payload = {
        retailer: searchParams.get('retailer'),
        customer_phone: searchParams.get('phone').replaceAll('-', ''),
        customer_name: searchParams.get('name') || null,
        cid: uuidv4(),
        amount,
        product: product?.id,
        product_pin: cardNumber,
      };
      const response = await ApiHelper.post(url.POST_PRE_PRDER, payload);
      history.push(`${Routes.RELOAD_CARD_RESULT.replace(':id', response.id)}`);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="omny-reloadCardReloadForm">
      <Container>
        <CustomerInfo
          customerName={searchParams.get('name')}
          customerPhone={searchParams.get('phone')}
        />
        <Field
          name="cardNumber"
          component={TextInput}
          label="OMNY card number"
          fetching={fetching}
          scanIcon={
            <img src={barCodeDark} alt="bar-code-scanner" onClick={onReScan} />
          }
          pattern="\d*"
          errorMessage={productError}
        />
        {!productError ? (
          <>
            <Field
              name="amount"
              component={TextInput}
              label={
                product
                  ? `Enter load amount (Range: $${parseInt(
                      product?.min_price || 0,
                    )} - $${parseInt(product?.max_price || 0)})`
                  : 'Enter load amount'
              }
              className="omny-reloadCardReloadForm__numberInput"
              type="number"
            />
            {product?.price_list?.length > 0 && (
              <PriceList
                prices={product.price_list}
                onSelect={onSelectAmount}
              />
            )}

            {amount && (
              <div className="omny-reloadCardReloadForm__reloadAmount">
                <p>Reload amount</p>
                <h3 className="omny-reloadCardReloadForm__amount">${amount}</h3>
              </div>
            )}
            <div className="d-grid">
              <Button
                onClick={handleSubmit(onSubmit)}
                disabled={!amount || Number(amount) > product?.max_price}
                loading={loading}
              >
                Submit pre-order
              </Button>
            </div>
          </>
        ) : (
          <div className="d-grid">
            <Button onClick={onReScan} loading={loading}>
              Re-Scan
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
};

ReloadCardReloadForm.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  handleSubmit: PropTypes.any,
  initialize: PropTypes.any,
  change: PropTypes.func,
};

export default reduxForm({
  form: 'RELOAD_CARD_FORM',
})(ReloadCardReloadForm);
