import { Header } from 'components';
import React from 'react';
import './styles.scss';
import Scanner from './Scanner';
import PropTypes from 'prop-types';
import Routes from 'routes/routes';

const ScanBarCode = (props) => {
  const onScanComplete = (code) => {
    const search = props.location.search
      ? props.location.search + `&code=${code}`
      : `?code=${code}`;
    props.history.push({
      pathname: Routes.RELOAD_CARD_RELOAD_FORM,
      search,
    });
  };
  return (
    <div className="omny-scanBarCode">
      <Header title="Scan bar code to activate" />
      <Scanner onScanComplete={onScanComplete} />
    </div>
  );
};

ScanBarCode.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
};

export default ScanBarCode;
