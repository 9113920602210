import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes/routes';
import './styles.scss';
import { SERVICECONSTANTS } from 'constants/common';

const Footer = ({ history, onlyFooter }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const redirectToTerm = () => {
    if (history.location.pathname !== Routes.TERM) {
      history.push(Routes.TERM);
    }
  };
  return (
    <section id="footer">
      {!onlyFooter && (
        <>
          <a
            href="#"
            onClick={scrollToTop}
            className="back-to-top-btn btn btn-gradient-primary"
            id="back-to-top"
            style={{ display: 'block' }}
          >
            <span className="triagle"></span>
          </a>
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12 footer-content">
                If you have questions or needing support, don’t hesitate to
                reach out to us at <b>{SERVICECONSTANTS.csPhoneNumber}</b>
                <br />
                or email us at{' '}
                <a href="mailto:omnyinfo@collnectsoftware.com">
                  omnyinfo@collnectsoftware.com
                </a>
                . A live solution specialist will be there to assist you.
              </div>
            </div>
          </div>
        </>
      )}
      <div className={`bg_black ${onlyFooter ? 'fix-bottom' : ''}`}>
        <div className="text-center ">
          <div className="text-primary-hover">
            {' '}
            Copyrights{' '}
            <a href="" onClick={redirectToTerm} className="text-body">
              ©2023 CollNect Software
            </a>
            . All rights reserved.{' '}
          </div>
        </div>
      </div>
    </section>
  );
};

Footer.propTypes = {
  history: PropTypes.any,
  onlyFooter: PropTypes.bool,
};

export default Footer;
