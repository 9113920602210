export default {
  businessName: 'business_name',
  businessLicense: 'business_license',
  taxId: 'tax_id',
  businessPhone: 'business_phone',
  zipCode: 'zip_code',
  businessLicenseImg: 'business_license_image_url',
  driverLicenseImg: 'driver_license_image_url',
  frontStoreImg: 'store_image_url',
  city: 'city',
  state: 'state',
  street: 'street',
  shippingName: 'shipping_name',
  shippingPhone: 'shipping_phone',
  shippingStreet: 'shipping_street',
  shippingCity: 'shipping_city',
  shippingState: 'shipping_state',
  shippingZipCode: 'shipping_zip_code',
  errorDetailSnapshot: 'error_detail_snapshot',
  bankName: 'bank_name',
  bankAccountHolder: 'bank_account_holder',
  bankAccountNumber: 'bank_account_number',
  bankRoutingNumber: 'bank_routing_number',
  bankImg: 'bank_image_url',
};
