import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.scss';

const USPhoneInput = ({
  label,
  placeholder,
  name,
  className,
  input,
  meta: { touched, error, warning },
}) => {
  const classNames = classnames(
    'form-floating mb-3 omny-phoneInput',
    className,
  );
  const formControlClassNames = classnames('form-control', {
    'is-invalid': touched === true && error !== undefined,
  });

  return (
    <div className={classNames}>
      <input
        type="text"
        className={formControlClassNames}
        id={name}
        pattern="\d*"
        placeholder={placeholder}
        {...input}
      />
      <label htmlFor={name}>{label}</label>
      {touched &&
        ((error && <div className="text-danger mt-2">{error}</div>) ||
          (warning && <div className="text-warning mt-2">{warning}</div>))}
    </div>
  );
};

USPhoneInput.defaultProps = {
  placeholder: 'name@example.com',
};

USPhoneInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  input: PropTypes.any,
  meta: PropTypes.any,
};

export default USPhoneInput;
