export default {
  DASHBOARD: '/',
  PROFILE: '/profile',
  LOGIN: '/login',
  LOGOUT: '/logout',
  UNAUTHORIZED: '/unauthorized',
  PAGE_COMING_SOON: '/pages-comingsoon',
  RELOAD_CARD_CUSTOMER_FORM: '/reload-card/customer-form',
  RELOAD_CARD_RELOAD_FORM: '/reload-card/reload-form',
  RELOAD_CARD_RESULT: '/reload-card/result/:id',
  RELOAD_CARD_RESULT_NEXT: '/reload-card/result-next/:id',
  SCAN_BAR_CODE: '/scan-bar-code',
  REGISTER: '/register',
  REGISTER_WELCOME: '/register-welcome',
  REGISTER_CONTACT: '/register/contact',
  REGISTER_BUSINESS: '/register/business/:id',
  REGISTER_BANK: '/register/bank/:id',
  REGISTER_TERM: '/register/term-condition/:id',
  REGISTER_VERIFICATION: '/register/:id/verify',
  REGISTER_SALE_AGENT_VERIFICATION: '/register/:id/sale-agent-verify',
  REGISTER_MANY_OTP_REQUEST: '/register/:id/many-otp-request',
  REGISTER_WAITING_FOR_APPROVAL: '/register/:id/waiting-for-approval',
  REGISTER_CONFRIMATION: '/register/confirmation/:id',
  REGISTER_ALREADY_IN_USE: '/register/:id/already-in-use',
  REGISTER_ACTIVATED: '/register/:id/activated',
  REGISTER_REJECTED: '/register/:id/rejected',
  REGISTER_REJECTED_REDIRECT: '/deep-link/retailer/register/:id/reject',
  REGISTER_APPROVED_REDIRECT: '/deep-link/retailer/register/:id/approve',
  REGISTER_NEW: '/register/:id/new',
  REGISTER_ACTIVATE_ACCOUNT: '/register/:id/activate-account',
  AGREEMENT: '/agreement',
  POLICY: '/policy',
  TERM: '/term',
};
