import React from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import {
  Dashboard,
  PageNotFound,
  PageComingsoon,
  ReloadCardCustomerForm,
  ScanBarCode,
  ReloadCardReloadForm,
  ReloadCardResult,
  ReloadCardResultNext,
  RegisterVerification,
  RegisterForm,
  Agreement,
  Policy,
  Term,
  RegisterWelcomeDesktop,
} from 'pages';
import Routes from './routes';

const RouteContainer = () => {
  return (
    <Router>
      <Switch>
        <Route
          path={Routes.RELOAD_CARD_CUSTOMER_FORM}
          component={ReloadCardCustomerForm}
          exact={true}
        />
        <Route
          path={Routes.RELOAD_CARD_RELOAD_FORM}
          component={ReloadCardReloadForm}
          exact={true}
        />
        <Route
          path={Routes.RELOAD_CARD_RESULT}
          component={ReloadCardResult}
          exact={true}
        />
        <Route
          path={Routes.RELOAD_CARD_RESULT_NEXT}
          component={ReloadCardResultNext}
          exact={true}
        />
        <Route
          path={Routes.SCAN_BAR_CODE}
          component={ScanBarCode}
          exact={true}
        />
        <Route
          path={Routes.REGISTER_WELCOME}
          component={RegisterWelcomeDesktop}
          exact={true}
        />
        <Route path={Routes.REGISTER} component={RegisterForm} />
        <Route
          path={Routes.REGISTER_VERIFICATION}
          component={RegisterVerification}
          exact={true}
        />
        <Route path={Routes.AGREEMENT} component={Agreement} exact={true} />
        <Route path={Routes.POLICY} component={Policy} exact={true} />
        <Route path={Routes.TERM} component={Term} exact={true} />
        <Route path={Routes.DASHBOARD} component={Dashboard} exact={true} />
        <Route path={Routes.PAGE_COMING_SOON} component={PageComingsoon} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Router>
  );
};

RouteContainer.propTypes = {
  layout: PropTypes.any,
};

export default RouteContainer;
