import React from 'react';
import { Row, Col } from 'reactstrap';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Validator from 'helpers/validator';
import UploadDocument from '../UploadDocument';
import { FormFieldMapping } from '../constants';

const PhotoDocument = ({ registerInfo, formValues }) => {
  const renderFieldError = (field) => {
    if (registerInfo && formValues) {
      const errorSnapshot = registerInfo.error_detail_snapshot;
      if (errorSnapshot) {
        return registerInfo[FormFieldMapping[field]] === formValues[field]
          ? errorSnapshot[FormFieldMapping[field]]
          : undefined;
      }
    }
    return undefined;
  };
  return (
    <div className="omny-photoDocument">
      <div className="row">
        <div className="col-md-6 col-xs-12">
          <div>
            <p className="fw-bold">Take photos documents</p>
            <p className="omny-photoDocument__desc">
              Please take photo of Business license, Driver license
            </p>
            <Row className="mt-3" xs={2}>
              <Col>
                <Field
                  component={UploadDocument}
                  label={'Business License'}
                  name="businessLicenseImg"
                  validate={[Validator.isRequired]}
                  errorMessage={renderFieldError('businessLicenseImg')}
                />
              </Col>
              <Col>
                <Field
                  component={UploadDocument}
                  label={'Driver License'}
                  name="driverLicenseImg"
                  validate={[Validator.isRequired]}
                  errorMessage={renderFieldError('driverLicenseImg')}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div className="xs-mt-4">
            <p className="fw-bold">Store’s front photo</p>
            <p className="omny-photoDocument__desc">
              Store photos make it easy for your customers to identify
            </p>
            <Row className="mt-3" xs={2}>
              <Col>
                <Field
                  name="frontStoreImg"
                  component={UploadDocument}
                  label={'Store’s photo'}
                  validate={[Validator.isRequired]}
                  errorMessage={renderFieldError('frontStoreImg')}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

PhotoDocument.propTypes = {
  registerInfo: PropTypes.object,
  formValues: PropTypes.object,
};

export default PhotoDocument;
