import React, { useEffect } from 'react';
import Routes from 'routes/routes';
import PropTypes from 'prop-types';
import OMNYLogo from 'assets/img/OMNYLogo.svg';
import './styles.scss';
import { SERVICECONSTANTS } from 'constants/common';

const Header = ({ history, disableSignup }) => {
  useEffect(() => {
    window.addEventListener('scroll', isOnTop);
    return () => {
      window.removeEventListener('scroll', isOnTop);
    };
  }, []);

  const isOnTop = () => {
    if (disableSignup) return;
    const header = document.getElementById('navbar-sticky');
    const backToTopBtn = document.getElementById('back-to-top');
    if (
      window.scrollY > 50 &&
      header &&
      !header.classList.contains('nav-sticky')
    ) {
      header.classList.add('nav-sticky');
      if (backToTopBtn) {
        backToTopBtn.style.display = 'block';
      }
    } else if (
      window.scrollY <= 50 &&
      header &&
      header.classList.contains('nav-sticky')
    ) {
      header.classList.remove('nav-sticky');
      if (backToTopBtn) {
        backToTopBtn.style.display = 'none';
      }
    }
  };

  const redirectToHome = () => {
    history.push(Routes.DASHBOARD);
  };

  const redirectToRegister = () => {
    history.push(Routes.REGISTER_WELCOME);
  };
  return (
    <header className="omny-retailer-header">
      <div
        className={`navbar navbar-expand-lg fixed-top navbar-custom sticky-dark ${
          disableSignup ? 'nav-sticky' : ''
        }`}
        id="navbar-sticky"
      >
        <div className="container">
          <div className="logo-omny">
            <img
              width="300"
              height="128"
              className="logo"
              onClick={redirectToHome}
              src={OMNYLogo}
            />
          </div>
          <ul className="nav nav-pills justify-content-center">
            <li className="nav-item for-support border-left">
              <span className="nav-link">
                For Support
                <br />
                {SERVICECONSTANTS.csPhoneNumber}
              </span>
            </li>
            {!disableSignup && (
              <li className="nav-item display-none">
                <button
                  type="button"
                  className="btn btn-danger btn-lg button-size"
                  onClick={redirectToRegister}
                >
                  Sign Up Free
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  history: PropTypes.any,
  disableSignup: PropTypes.bool,
};

export default Header;
