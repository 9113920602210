import { Button } from 'components';
import React from 'react';
import { Container } from 'reactstrap';
import CircleCheckboxIcon from './circle-checkbox.svg';
import './styles.scss';
import Routes from 'routes/routes';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateRegisterInfoRequested } from 'store/actions';

const RegisterActivated = ({ history }) => {
  const dispatch = useDispatch();
  const onCreateRegister = () => {
    dispatch(updateRegisterInfoRequested());
    history.replace(Routes.REGISTER_WELCOME);
  };
  return (
    <div className="omny-registerStatus">
      <Container>
        <div>
          <div className="text-center">
            <img src={CircleCheckboxIcon} alt="circle-checkbox-icon" />
          </div>
          <p className="text-center mt-4">Account activate successful</p>
        </div>
        <div className="d-grid mt-5 justify-content-center">
          <Button color="danger button-size" onClick={onCreateRegister}>
            Close
          </Button>
        </div>
      </Container>
    </div>
  );
};

RegisterActivated.propTypes = {
  history: PropTypes.any,
};

export default RegisterActivated;
