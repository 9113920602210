import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Routes from './routes';
import './assets/scss/_app.scss';

const App = (props) => {
  return (
    <div className="omny-layout">
      <Routes layout={props.layout} />
    </div>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
