import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import RegisterProgress from '../RegisterProgress';
import RegisterContact from '../Contact';
import RegisterBusiness from '../Business';
import RegisterBank from '../Bank';
import RegisterTerm from '../Term';
import {
  RegisterConfirmation,
  RegisterManyOTPRequest,
  RegisterWaitingForApproval,
  RegisterAlreadyInUse,
  RegisterRejected,
  RegisterActivated,
  RegisterNew,
} from '../Status';
import { RegisterVerificationRedirect } from '../Verification';
import RegisterActivateAccount from '../ActivateRetailerAccount';
import Routes from 'routes/routes';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { REGISTER_STEP } from 'constants/common';
import './styles.scss';
import { Footer, PageHeader } from 'components';

const RegisterForm = ({ history }) => {
  const registerInfo = useSelector((state) => state.app.Register.registerInfo);
  const [isShowProgress, setIsShowProgress] = useState(true);
  const [isActivatePage, setIsActivatePage] = useState(false);

  useEffect(() => {
    const { pathname } = history.location;
    const termUrl = Routes.REGISTER_TERM.replace(':id', registerInfo?.id);
    const confirmationUrl = Routes.REGISTER_CONFRIMATION.replace(
      ':id',
      registerInfo?.id,
    );
    const activateUrl = Routes.REGISTER_ACTIVATE_ACCOUNT.replace(
      ':id',
      registerInfo?.id,
    );
    if (activateUrl === pathname) {
      setIsActivatePage(true);
    } else {
      setIsActivatePage(false);
    }
    if (termUrl === pathname || confirmationUrl === pathname) {
      setIsShowProgress(false);
    } else {
      setIsShowProgress(true);
    }
  }, [history.location.pathname]);

  return (
    <div className="omny-registerForm">
      <PageHeader history={history} disableSignup />
      {isShowProgress && (
        <div className="register-header">
          <Container>
            <h3 className="rt-title-heading">
              <span className="letters">
                {isActivatePage
                  ? 'Activate to your OMNY Retailer account'
                  : 'Register your business'}
              </span>
            </h3>
          </Container>
        </div>
      )}
      <Container>
        <div className={`registerForm ${!isShowProgress ? 'mt-5' : ''}`}>
          {isShowProgress && (
            <div className="row">
              <div className="mg-auto col-md-6 col-xs-12">
                <RegisterProgress
                  activeStep={
                    registerInfo ? REGISTER_STEP[registerInfo.step] : 1
                  }
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="form-container mg-auto col-md-9 col-xs-12">
              <Switch>
                <Route
                  path={Routes.REGISTER_CONTACT}
                  component={RegisterContact}
                  exact={true}
                />
                <Route
                  path={Routes.REGISTER_BUSINESS}
                  component={RegisterBusiness}
                  exact={true}
                />
                <Route
                  path={Routes.REGISTER_BANK}
                  component={RegisterBank}
                  exact={true}
                />
                <Route
                  path={Routes.REGISTER_TERM}
                  component={RegisterTerm}
                  exact={true}
                />
                <Route
                  path={Routes.REGISTER_CONFRIMATION}
                  component={RegisterConfirmation}
                  exact={true}
                />
                <Route
                  path={Routes.REGISTER_MANY_OTP_REQUEST}
                  component={RegisterManyOTPRequest}
                  exact={true}
                />
                <Route
                  path={Routes.REGISTER_WAITING_FOR_APPROVAL}
                  component={RegisterWaitingForApproval}
                  exact={true}
                />
                <Route
                  path={Routes.REGISTER_ALREADY_IN_USE}
                  component={RegisterAlreadyInUse}
                  exact={true}
                />
                <Route
                  path={Routes.REGISTER_REJECTED}
                  component={RegisterRejected}
                  exact={true}
                />
                <Route
                  path={Routes.REGISTER_ACTIVATED}
                  component={RegisterActivated}
                  exact={true}
                />
                <Route
                  path={Routes.REGISTER_REJECTED_REDIRECT}
                  component={RegisterVerificationRedirect}
                  exact={true}
                />
                <Route
                  path={Routes.REGISTER_APPROVED_REDIRECT}
                  component={RegisterVerificationRedirect}
                  exact={true}
                />
                <Route
                  path={Routes.REGISTER_NEW}
                  component={RegisterNew}
                  exact={true}
                />
                <Route
                  path={Routes.REGISTER_ACTIVATE_ACCOUNT}
                  component={RegisterActivateAccount}
                  exact={true}
                />
              </Switch>
            </div>
          </div>
        </div>
      </Container>
      <Footer onlyFooter />
    </div>
  );
};

RegisterForm.propTypes = {
  history: PropTypes.any,
};

export default RegisterForm;
