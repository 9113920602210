import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Button } from 'components';
import './styles.scss';
import PreOrderHeader from '../Result/PreOrderHeader';
import ApiHelper from 'helpers/apiHelper';
import * as url from 'helpers/urlHelper';
import Routes from 'routes/routes';

const ReloadCardResutNext = ({ history }) => {
  const [preOrder, setPreOrder] = useState();
  const { id } = useParams();

  useEffect(() => {
    getPreOrderDetail();
  }, []);

  const getPreOrderDetail = async () => {
    try {
      const response = await ApiHelper.get(
        url.GET_PRE_ORDER.replace('{id}', id),
      );
      setPreOrder(response);
    } catch (error) {}
  };

  const onBack = () => {
    history.push(Routes.RELOAD_CARD_CUSTOMER_FORM);
  };

  return (
    <div className="omny-reloadCardResultNext">
      <Container>
        <PreOrderHeader seq={preOrder?.seq} date={preOrder?.created_at} />
        <h4 className="text-center omny-reloadCardResultNext__note">
          Please wait for the cashier to call your Order ID
        </h4>
        <p className="text-center">Order ID valid today only</p>
        <div className="d-grid">
          <Button color="btn btn-primary btn-lg omny-button" onClick={onBack}>
            Back to home
          </Button>
        </div>
      </Container>
    </div>
  );
};

ReloadCardResutNext.propTypes = {
  history: PropTypes.any,
};

export default ReloadCardResutNext;
