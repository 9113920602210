import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Checkbox = ({
  className,
  label,
  input,
  name,
  meta: { touched, error, warning },
}) => {
  const classNames = classnames('form-check mb-3 omny-checkbox', className);
  return (
    <div className={classNames}>
      <input
        className="form-check-input"
        type="checkbox"
        id={name}
        checked={input.value ? true : false}
        onChange={input.onChange}
      />
      <label className="form-check-label" htmlFor="flexCheckChecked">
        {label}
      </label>
      {touched &&
        ((error && <div className="text-danger mt-2">{error}</div>) ||
          (warning && <div className="text-warning mt-2">{warning}</div>))}
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.any,
  name: PropTypes.string.isRequired,
  meta: PropTypes.any,
};

export default Checkbox;
