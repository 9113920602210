import React, { useEffect, useState } from 'react';
import * as url from 'helpers/urlHelper';
import ApiHelper from 'helpers/apiHelper';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateRegisterInfoRequested } from 'store/actions';
import { useSelector } from 'react-redux';
import VerificationCode from 'components/VerificationCode';
import { REGISTER_STATUS } from 'constants/common';
import Routes from 'routes/routes';

const RegisterVerificationRedirect = ({ history }) => {
  const dispatch = useDispatch();
  const { id: registerId } = useParams();
  const registerInfo = useSelector((state) => state.app.Register.registerInfo);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (registerId) {
      onSendSMS();
      getRegisterInfo();
    }
  }, [registerId]);

  const onSendSMS = async () => {
    try {
      await ApiHelper.post(
        url.POST_REGISTER_RESEND_SMS.replace('{registerId}', registerId),
      );
    } catch (error) {}
  };

  const getRegisterInfo = async () => {
    try {
      const res = await ApiHelper.get(
        url.GET_REGISTER_INFO.replace('{registerId}', registerId),
      );
      dispatch(updateRegisterInfoRequested(res));
    } catch (error) {}
  };

  const onSubmit = async (code) => {
    try {
      setSubmitting(true);
      await ApiHelper.post(
        url.POST_REGISTER_VERIFICATION_CODE.replace('{registerId}', registerId),
        { code },
      );

      if (registerInfo.status === REGISTER_STATUS.approved) {
        if (!registerInfo.user_activated) {
          return history.replace(
            Routes.REGISTER_ACTIVATE_ACCOUNT.replace(':id', registerId),
          );
        }
        return history.replace(
          Routes.REGISTER_ACTIVATED.replace(':id', registerId),
        );
      }

      if (registerInfo.status === REGISTER_STATUS.rejected) {
        return history.replace(
          Routes.REGISTER_BUSINESS.replace(':id', registerId),
        );
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <VerificationCode
      title="Verification"
      desc={'Enter 6-digit verification just send to'}
      phone={registerInfo && registerInfo.phone}
      loading={submitting}
      onCompleted={onSubmit}
    />
  );
};

RegisterVerificationRedirect.propTypes = {
  history: PropTypes.any,
};

export default RegisterVerificationRedirect;
