import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CheckWhiteIcon from './check-white.svg';

const RegisterProgressStep = ({ activeStep, stepNumber, label }) => {
  return (
    <div
      className={classnames('omny-registerProgress__step', {
        active: activeStep >= stepNumber,
      })}
    >
      <div className="omny-registerProgress__stepNumber">
        {activeStep > stepNumber ? (
          <img src={CheckWhiteIcon} alt="check-icon" />
        ) : (
          stepNumber
        )}
      </div>
      <div className="omny-registerProgress__stepLabel">{label}</div>
    </div>
  );
};

RegisterProgressStep.propTypes = {
  activeStep: PropTypes.number,
  stepNumber: PropTypes.number,
  label: PropTypes.any,
};

const RegisterProgress = ({ activeStep }) => {
  return (
    <div className="omny-registerProgress">
      <RegisterProgressStep
        stepNumber={1}
        activeStep={activeStep}
        label={
          <>
            Contact
            <br />
            information
          </>
        }
      />
      <RegisterProgressStep
        stepNumber={2}
        activeStep={activeStep}
        label={
          <>
            Business
            <br />
            information
          </>
        }
      />
      <RegisterProgressStep
        stepNumber={3}
        activeStep={activeStep}
        label={
          <>
            Bank
            <br />
            information
          </>
        }
      />
    </div>
  );
};

RegisterProgress.propTypes = {
  activeStep: PropTypes.number,
};

export default RegisterProgress;
