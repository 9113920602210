import React, { useEffect, useState } from 'react';
import BankInformation from './BankInformation';
import BankPhotoDocument from './BankPhotoDocument';
import { reduxForm, getFormValues } from 'redux-form';
import './styles.scss';
import { useParams } from 'react-router-dom';
import ApiHelper from 'helpers/apiHelper';
import * as url from 'helpers/urlHelper';
import { updateRegisterInfoRequested } from 'store/actions';
import { Button, OutlinedButton } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Routes from 'routes/routes';

const RegisterBank = ({ handleSubmit, initialize, history }) => {
  const { id: registerId } = useParams();
  const dispatch = useDispatch();
  const registerInfo = useSelector((state) => state.app.Register.registerInfo);
  const [submitting, setSubmitting] = useState(false);

  const formValues = useSelector((state) =>
    getFormValues('REGISTER_BANK_FORM')(state),
  );

  useEffect(() => {
    if (registerId) {
      getRegisterInfo(registerId);
    }
  }, [registerId]);

  useEffect(() => {
    if (registerInfo) {
      initialize({
        bankName: registerInfo.bank_name,
        bankAccountHolder: registerInfo.bank_account_holder,
        bankAccountNumber: registerInfo.bank_account_number,
        bankRoutingNumber: registerInfo.bank_routing_number,
        bankImg: registerInfo.bank_image_url,
      });
    }
  }, [registerInfo]);

  const getRegisterInfo = async (registerId) => {
    try {
      const res = await ApiHelper.get(
        url.GET_REGISTER_INFO.replace('{registerId}', registerId),
      );
      dispatch(updateRegisterInfoRequested(res));
    } catch (error) {}
  };

  const onBack = () => {
    history.goBack();
  };

  const onSubmit = async (values) => {
    const payload = {
      bank_name: values.bankName,
      bank_account_holder: values.bankAccountHolder,
      bank_account_number: values.bankAccountNumber,
      bank_routing_number: values.bankRoutingNumber,
      bank_image_url: values.bankImg,
    };
    try {
      setSubmitting(true);
      const response = await ApiHelper.post(
        url.POST_REGISTER_BANK_INFO.replace('{registerId}', registerInfo?.id),
        payload,
      );
      dispatch(updateRegisterInfoRequested(response));
      history.push(Routes.REGISTER_TERM.replace(':id', response.id));
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div className="omny-registerBank">
      <BankInformation registerInfo={registerInfo} formValues={formValues} />
      <BankPhotoDocument registerInfo={registerInfo} formValues={formValues} />
      <div className="row">
        <div className="col-12">
          <hr className="devider"></hr>
        </div>
      </div>
      <div className="flex-arround">
        <OutlinedButton onClick={onBack}>Back</OutlinedButton>
        <Button
          color="danger button-size"
          onClick={handleSubmit(onSubmit)}
          loading={submitting}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

RegisterBank.propTypes = {
  handleSubmit: PropTypes.func,
  history: PropTypes.any,
  initialize: PropTypes.func,
  change: PropTypes.func,
};

export default reduxForm({
  form: 'REGISTER_BANK_FORM',
})(RegisterBank);
