import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PriceList = ({ prices, className, onSelect }) => {
  const classNames = classnames('omny-priceList', className);
  return (
    <div
      className={classNames}
      style={{ gridTemplateColumns: `repeat(${prices.length}, 1fr)` }}
    >
      {prices.map((price) => (
        <a
          key={price}
          className="omny-priceList__item"
          onClick={() => onSelect(price)}
        >
          <strong>${price}</strong>
        </a>
      ))}
    </div>
  );
};

PriceList.propTypes = {
  prices: PropTypes.array,
  className: PropTypes.string,
  onSelect: PropTypes.func,
};

export default PriceList;
