import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useContainerDimensions, useTimer } from 'helpers/hook';
import { Button } from 'components';
import './styles.scss';

const VerificationCode = ({
  title,
  desc,
  phone,
  loading,
  onCompleted,
  isPopup,
  isSkip,
  onSkip,
}) => {
  const containerRef = useRef();
  const { width: containerWidth } = useContainerDimensions(containerRef);
  const { _, seconds } = useTimer(0, 60);
  const [code, setCode] = useState();
  const numberOfItems = 6;
  const itemSpacing = 10;
  const itemWidth =
    (containerWidth - numberOfItems * itemSpacing) / numberOfItems;

  const styles = {
    '--codeInputItemWidth': `${itemWidth}px`,
  };

  useEffect(() => {
    if (code && code?.length === 6) {
      onCompleted(code);
    }
  }, [code]);

  return (
    <div className="omny-verificationCode">
      <Container>
        {title && <p className="fw-bold">{title}</p>}
        <p className={`${!isPopup ? 'mt-4' : ''} mb-3`}>
          {desc && (
            <>
              {desc} {phone && <strong>{phone}</strong>}
            </>
          )}
        </p>
        <div className="row">
          <div className="mg-auto col-md-10 col-xs-12">
            <div ref={containerRef} style={styles}>
              <ReactInputVerificationCode
                onChange={setCode}
                length={numberOfItems}
                placeholder=""
                autoFocus
              />
            </div>
          </div>
        </div>
        {isPopup &&
          (seconds > 0 ? (
            <p className="mt-3 fst-italic text-center">
              Resend verification code in {seconds} seconds
            </p>
          ) : (
            <div className="resend-code mt-3 d-grid">
              <a href="" alt="resend code">
                Resend verification code
              </a>
            </div>
          ))}
        <div
          className={`${
            isPopup ? 'justify-content-center' : ''
          } d-grid mt-5 mb-4`}
        >
          <Button
            color={isPopup && 'danger button-size'}
            onClick={() => onCompleted(code)}
            disabled={!code || code.length < 6}
            loading={loading}
          >
            Submit
          </Button>
        </div>
        {!isPopup &&
          (seconds > 0 ? (
            <p className="fst-italic text-center">
              Resend verification code in {seconds} seconds
            </p>
          ) : (
            <div className="d-grid">
              <button
                type="button"
                className="btn btn-link omny-registerVerification__resendButton"
              >
                {' '}
                Resend verification code
              </button>
            </div>
          ))}
        {isSkip && (
          <div className="d-grid">
            <a className="text-center" onClick={onSkip}>
              Skip
            </a>
          </div>
        )}
      </Container>
    </div>
  );
};

VerificationCode.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  phone: PropTypes.string,
  loading: PropTypes.bool,
  onCompleted: PropTypes.func,
  isPopup: PropTypes.bool,
  isSkip: PropTypes.bool,
  onSkip: PropTypes.func,
};

export default VerificationCode;
