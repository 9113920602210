import React from 'react';
import { Field } from 'redux-form';
import { Checkbox } from 'components';
import { PhoneInput, TextInput } from 'components';
import Normalizer from 'helpers/normalizer';
import Validator from 'helpers/validator';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { FormFieldMapping } from '../constants';

const ShippingAddress = ({
  isSameBusinessAddress,
  registerInfo,
  formValues,
}) => {
  const renderFieldError = (field) => {
    if (registerInfo && formValues) {
      const errorSnapshot = registerInfo.error_detail_snapshot;
      if (errorSnapshot) {
        return registerInfo[FormFieldMapping[field]] === formValues[field]
          ? errorSnapshot[FormFieldMapping[field]]
          : undefined;
      }
    }
    return undefined;
  };
  return (
    <div className="my-4">
      <p className="fw-bold">Shipping address</p>
      <Field
        component={Checkbox}
        name="isSameBusinessAddress"
        label="Same business address"
      />
      {!isSameBusinessAddress && (
        <Collapse isOpen={!isSameBusinessAddress}>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <Field
                name="shippingName"
                component={TextInput}
                label="Recipient"
                validate={[Validator.isRequired]}
                errorMessage={renderFieldError('shippingName')}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <Field
                name="shippingPhone"
                component={PhoneInput}
                label="Phone number"
                className="omny-registerForm__phoneInput"
                normalize={Normalizer.normalizePhone}
                validate={[Validator.isRequired]}
                errorMessage={renderFieldError('shippingPhone')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                name="shippingStreet"
                component={TextInput}
                label="Address"
                validate={[Validator.isRequired]}
                errorMessage={renderFieldError('shippingStreet')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-xs-12">
              <Field
                name="shippingZipCode"
                component={TextInput}
                label="Zip code"
                pattern="\d*"
                validate={[Validator.isRequired]}
                errorMessage={renderFieldError('shippingZipCode')}
              />
            </div>
            <div className="col-md-4 col-xs-12">
              <Field
                name="shippingCity"
                component={TextInput}
                label="City"
                validate={[Validator.isRequired]}
                errorMessage={renderFieldError('shippingCity')}
              />
            </div>
            <div className="col-md-4 col-xs-12">
              <Field
                name="shippingState"
                component={TextInput}
                label="State"
                validate={[Validator.isRequired]}
                errorMessage={renderFieldError('shippingState')}
              />
            </div>
          </div>
        </Collapse>
      )}
    </div>
  );
};

ShippingAddress.propTypes = {
  isSameBusinessAddress: PropTypes.bool,
  registerInfo: PropTypes.object,
  formValues: PropTypes.object,
};
export default ShippingAddress;
