import axios from 'axios';
import Config from 'config.js';
import * as url from './urlHelper';
import StorageConstants from 'constants/storage';
import toastr from 'toastr';

const axiosApi = axios.create({
  baseURL: Config.API_URL,
  Accept: 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded',
});

axiosApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(StorageConstants.ACCESS_TOKEN);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const errorCode = error.response.status;
    if (errorCode === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem(StorageConstants.REFRESH_TOKEN);
      const res = await axiosApi.post(url.POST_REFERSH_TOKEN, {
        token: refreshToken,
      });
      localStorage.setItem(StorageConstants.ACCESS_TOKEN, res.token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.token;
      return axiosApi(originalRequest);
    }
    const errorMessage = error.response.data.message;
    if (errorMessage) {
      toastr.error(errorMessage);
    }

    return Promise.reject(error);
  },
);

async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export default {
  get,
  post,
  put,
  del,
};
