import React, { useEffect, useState } from 'react';
import * as url from 'helpers/urlHelper';
import ApiHelper from 'helpers/apiHelper';
import { useParams } from 'react-router-dom';
import Routes from 'routes/routes';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateRegisterInfoRequested } from 'store/actions';
import { useSelector } from 'react-redux';
import { REGISTER_STATUS } from 'constants/common';
import VerificationCode from 'components/VerificationCode';

const RegisterVerification = ({ history }) => {
  const dispatch = useDispatch();
  const { id: registerId } = useParams();
  const { registerInfo, phoneStatus } = useSelector(
    (state) => state.app.Register,
  );
  const [verifyCount, setVerifyCount] = useState(0);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (registerId) {
      getRegisterInfo(registerId);
    }
  }, [registerId]);

  useEffect(() => {
    if (verifyCount === 5) {
      history.replace(
        Routes.REGISTER_MANY_OTP_REQUEST.replace(':id', registerId),
      );
    }
  }, [verifyCount]);

  const getRegisterInfo = async (registerId) => {
    try {
      const res = await ApiHelper.get(
        url.GET_REGISTER_INFO.replace('{registerId}', registerId),
      );
      dispatch(updateRegisterInfoRequested(res));
    } catch (error) {}
  };

  const onSumbit = async (code) => {
    try {
      setSubmitting(true);
      setVerifyCount(verifyCount + 1);
      await ApiHelper.post(
        url.POST_REGISTER_VERIFICATION_CODE.replace('{registerId}', registerId),
        { code },
      );
      if (phoneStatus && phoneStatus.status === REGISTER_STATUS.new) {
        return history.replace(Routes.REGISTER_NEW.replace(':id', registerId));
      }
      if (
        [
          REGISTER_STATUS.approved,
          REGISTER_STATUS.activated,
          REGISTER_STATUS.waiting_for_approval,
        ].includes(registerInfo.status)
      ) {
        return history.replace(
          Routes.REGISTER_ALREADY_IN_USE.replace(':id', registerId),
        );
      }

      if (registerInfo.status === REGISTER_STATUS.rejected) {
        return history.replace(
          Routes.REGISTER_REJECTED.replace(':id', registerId),
        );
      }

      return history.replace(
        Routes.REGISTER_BUSINESS.replace(':id', registerId),
      );
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <VerificationCode
      title="Verification phone number"
      desc={'Enter 6-digit verification just send to'}
      phone={registerInfo && registerInfo.phone}
      loading={submitting}
      onCompleted={onSumbit}
    />
  );
};

RegisterVerification.propTypes = {
  history: PropTypes.any,
};

export default RegisterVerification;
