import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.scss';

const Select = ({
  label,
  className,
  name,
  input,
  options,
  meta: { touched, error, warning },
  getValueOption,
  getLabelOption,
}) => {
  const classNames = classnames('form-floating mb-3 omny-select', className);
  const formControlClassNames = classnames('form-select', {
    'is-invalid': touched === true && error !== undefined,
  });

  return (
    <div className={classNames}>
      <select
        className={formControlClassNames}
        id={name}
        aria-label="Floating label select example"
        onChange={input.onChange}
        value={input.value}
      >
        <option value={undefined} key={'option-empty'}></option>
        {options?.map((op) => (
          <option
            selected={getValueOption(op) == input.value}
            value={getValueOption(op)}
            key={getValueOption(op)}
          >
            {getLabelOption(op)}
          </option>
        ))}
      </select>
      <label htmlFor="floatingSelect">{label}</label>
      {touched &&
        ((error && <div className="text-danger mt-2">{error}</div>) ||
          (warning && <div className="text-warning mt-2">{warning}</div>))}
    </div>
  );
};

Select.defaultProps = {
  options: [],
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  input: PropTypes.any,
  meta: PropTypes.object,
  options: PropTypes.array,
  getValueOption: PropTypes.func,
  getLabelOption: PropTypes.func,
};

export default Select;
