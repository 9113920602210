import { combineReducers } from 'redux';
import Layout from './layout/reducer';
import Register from './register/reducer';

const rootReducer = combineReducers({
  Layout,
  Register,
});

export default rootReducer;
