import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiHelper from 'helpers/apiHelper';
import * as url from 'helpers/urlHelper';
import { updateRegisterInfoRequested } from 'store/actions';
import { useDispatch } from 'react-redux';
import { Container, FormGroup, Input, Label } from 'reactstrap';
import { Button, OutlinedButton } from 'components';
import VerificationCode from 'components/VerificationCode';
import PropTypes from 'prop-types';
import Routes from 'routes/routes';
import { useParams } from 'react-router-dom';

const RegisterTerm = ({ history }) => {
  const dispatch = useDispatch();
  const registerInfo = useSelector((state) => state.app.Register.registerInfo);
  const { id: registerId } = useParams();
  const [isCheck, setIsCheck] = useState(false);
  const [saleAgent, setSaleAgent] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [verifyCount, setVerifyCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (registerId) {
      getRegisterInfo(registerId);
    }
  }, [registerId]);

  useEffect(() => {
    if (verifyCount === 5) {
      history.replace(
        Routes.REGISTER_MANY_OTP_REQUEST.replace(':id', registerId),
      );
    }
  }, [verifyCount]);

  const getRegisterInfo = async (registerId) => {
    try {
      const res = await ApiHelper.get(
        url.GET_REGISTER_INFO.replace('{registerId}', registerId),
      );
      dispatch(updateRegisterInfoRequested(res));
    } catch (error) {}
  };

  const onSubmit = async () => {
    if (
      registerInfo?.status === 'new' &&
      registerInfo?.sale_agent_verified_at === null &&
      registerInfo?.sale_agent_name !== null
    ) {
      onSendSMS();
      setModalOpen(true);
    } else {
      hanldeSubmit();
    }
  };

  const hanldeSubmit = async (isSkip) => {
    if (registerId == null) {
      return;
    }
    try {
      if (isSkip) {
        setVerifying(true);
      } else {
        setSubmitting(true);
      }
      await ApiHelper.post(
        url.POST_REGISTER_APPLICATION.replace('{registerId}', registerId),
      );
      history.push(Routes.REGISTER_CONFRIMATION.replace(':id', registerId));
    } catch (error) {
    } finally {
      if (isSkip) {
        setVerifying(false);
      } else {
        setSubmitting(false);
      }
    }
  };

  const onSendSMS = async () => {
    try {
      const res = await ApiHelper.post(
        url.POST_REGISTER_SALE_AGENT_RESEND_SMS.replace(
          '{registerId}',
          registerId,
        ),
      );
      setSaleAgent(res);
    } catch (error) {}
  };

  const onVerifyCode = async (code) => {
    try {
      setVerifying(true);
      setVerifyCount(verifyCount + 1);
      await ApiHelper.post(
        url.POST_REGISTER_VERIFICATION_SALE_AGENT_CODE.replace(
          '{registerId}',
          registerId,
        ),
        { code },
      );
      await ApiHelper.post(
        url.POST_REGISTER_APPLICATION.replace('{registerId}', registerId),
      );
      return history.replace(
        Routes.REGISTER_ACTIVATE_ACCOUNT.replace(':id', registerId),
      );
    } catch (error) {
    } finally {
      setVerifying(false);
    }
  };

  const onCloseModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div className="omny-registerTerm">
      <Container>
        <p className="fw-bold">Retailer Agreement</p>
        <p className="my-4">
          This Agreement (&quot;Agreement&quot;) is between{' '}
          <strong>CollNect Software, Inc. (“CollNect”)</strong>, a Georgia
          corporation with its principal place of business located at 4500
          Satellite Blvd, Suite 2320, Duluth, Georgia 30096 and{' '}
          <strong>{registerInfo?.business_name}</strong> and whose mailing
          address is 3805 Crestwood Parkway.
        </p>
        <p className="fw-bold">AGREEMENT TERMS</p>
        <p className="mt-4">1. RETAILER’S OBLIGATIONS</p>
        <p>
          1.1 Login and Password: The Retailer is fully responsible for: (a)
          sales made through Retailer’s login and password; (b) safeguarding the
          login and password against unauthorized access, usage, and sale.
          Retailer is liable for all unauthorized sales made through its login
          and password.
        </p>
        <p>
          1.2 Fraud: Retailer agrees to not engage in fraudulent acts,
          including: (a) charging more than the retail price of the cards; (b)
          refusing to submit monies due to CollNect; (c) engaging in intentional
          activities that adversely affect CollNect and undermines CollNect’s
          business reputation.
        </p>
        <p>
          1.3 Indemnity: Retailer agrees to indemnify and hold CollNect and its
          employees and affiliates harmless and free from all liabilities that
          may result from Retailer’s negligence or intentional actions.
        </p>
        <p>
          1.4 Tax: Retailer is responsible for its own taxes. Retailer agrees to
          provide CollNect with its retailer certificate and sales tax exemption
          certificate. CollNect is not responsible for Retailer’s failure to
          collect or pay its taxes.
        </p>
        <p>1.5 Sales via OMNY App Payment Terms:</p>
        <p>
          a. Daily Invoice - Daily ACH: CollNect will issue a daily invoice for
          the previous day’s sales, and ACH debit the Retailer’s bank account.
          For Friday, Saturday, and Sunday sales, CollNect will send out an
          invoice and ACH debit Retailer at the same time on Monday.
        </p>
        <p>
          b. Retailer ACH Debit Payment Agreement: If CollNect cannot ACH debit
          Retailer’s bank account because of Non-Sufficient Funds (NSF),
          Retailer’s account will be temporarily deactivated. Retailer is
          responsible for $25.00 NSF (Non-Sufficient Funds) fee per transaction.
        </p>
        <p className="mt-4">2. COLLNECT’S OBLIGATIONS</p>
        <p>
          2.1 System Access: CollNect agrees to (a) provide Retailer with access
          to CollNect’s Products Portals and Online Systems; (b) assign login
          password; and (c) provide customer service.
        </p>
        <p className="mt-4">3. CONFIDENTIALITY</p>
        <p>
          Retailer agrees not to disclose any information received or obtained
          from CollNect or its affiliates and associates to any other third
          parties without CollNect’s written permission. Information considered
          confidential includes, but is not limited to: promotional plans,
          business strategies, customer surveys, customer list, retailer list,
          distributor list, etc. Notwithstanding any of the above, this
          confidentiality term remains enforceable even after this Agreement has
          been terminated.
        </p>
        <p className="mt-4">4. COLLNECT CUSTOMERS’ PRIVACY</p>
        <p>
          Customers who purchased and used CollNect Products are considered as
          CollNect customers. Retailer agrees not to disclose customer
          information obtained from the selling of CollNect products to anyone
          not authorized by the customer or by CollNect. Retailer may allow its
          designated employees or agents to access the customer information only
          if the customer or CollNect has authorized it for the purpose of
          making sales.
        </p>
        <p className="mt-4">5. DURATION & TERMINATION</p>
        <p>
          This Agreement remains in effect until either party chooses to
          terminate it. The terminating party must provide the other party
          written notice of its intention 30 days in advance. If CollNect
          determines that Retailer has violated any of the terms in this
          Agreement, then CollNect reserves the right to terminate this
          Agreement immediately. A termination resulting from Retailer’s breach
          of Agreement will disqualify Retailer from receiving all compensations
          or privileges up to the date of any violations. Within 5 business days
          after termination, Retailer must turn over all customer accounts to
          CollNect. CollNect reserves the right to terminate the customer
          accounts. Termination based upon Retailer’s fault will result in
          Retailer being liable to its customers for their accounts being
          terminated.
        </p>
        <p className="mt-4">6. GENERAL:</p>
        <p>
          6.1 Non-Exclusive: Retailer’s appointment as an authorized retailer is
          non- exclusive.
        </p>
        <p>
          6.2 Use of Intellectual Property: Retailer may be permitted to use the
          names, logos, trademarks, service marks, website contents, and other
          intellectual property owned by, adopted by, or licensed to CollNect
          (“Trademarks”) if it requested and received an authorization from
          CollNect in writing. Retailer must submit to CollNect for review how
          such Trademarks would be used prior to any actual usage. Any rights
          that CollNect may grant Retailer to use the Trademarks shall be
          extinguished and automatically reverted to CollNect upon expiration or
          termination of this Agreement and Retailer shall immediately
          discontinue all use of the Trademarks and all copies thereof (in
          whatever form) including all signage and marketing materials, and
          return the same to CollNect upon such termination.
        </p>
        <p>6.3 Assignment:</p>
        <p>
          6.3.1 General Prohibition on Assignment: Any attempt by any party to
          assign this Agreement or that party’s rights under this Agreement
          shall be void unless approved in writing, by the other party.
        </p>
        <p>
          6.3.2 By CollNect: CollNect may assign this Agreement or any or all of
          its rights and obligations under this Agreement without the consent of
          Retailer in connection with any merger, acquisition, or reorganization
          of CollNect, and the assignee or successor in interest may provide
          services to Retailer under a new name. Upon such assignment, CollNect
          shall be released and relieved of all obligations under this
          Agreement.
        </p>
        <p>
          6.4 Governing Law and Venue: This Agreement shall be construed and
          enforced in accordance with, and shall be governed by, the laws of the
          State of Georgia. Any action or proceeding under this Agreement shall
          be brought in the courts in the State of Georgia, County of Gwinnett,
          and all parties waive any right to object to such courts or venue.{' '}
        </p>
        <p>
          6.5 Representation of No Conflicting Agreement: Each party represents
          that entering into this Agreement has not caused it to breach of any
          other agreement, license, order or organization documents to which it
          party or by which it is bound.
        </p>
        <p>
          6.6 Attorneys’ Fees: In any action or proceeding brought to construe
          or enforce any provisions of this Agreement, the prevailing party
          shall receive, in addition to any other remedy to which it may be
          entitled, its reasonable attorneys&apos;s fees and compensation for
          all costs incurred in pursuing such action or proceeding, including
          expert witnesses&apos;s fees and costs.{' '}
        </p>
        <p>
          6.7 Entire Agreement: This Agreement, including all schedules and
          attachments hereto, constitutes the entire and sole agreement between
          the parties with respect to its subject matter and supersedes all
          prior understandings, arrangements or agreements, whether verbal or
          written, between the parties hereto not contained in this Agreement.
          No modification, renewal, extension or waiver of any of the provisions
          of this Agreement shall be binding upon either party unless made in
          writing and signed by the parties. No waiver of any breach of this
          Agreement shall be deemed a waiver of any other or subsequent breach.
        </p>
        <p className="fw-bold my-4">
          ACCOUNT ACH DEBIT AUTHORIZATION TO BE COMPLETED BY RETAILER
        </p>
        <p>
          1. The undersigned Agent authorizes CollNect Software, Inc. to
          initiate debit and credit entries (via automated clearing house or
          other electronic means) to or from the account specified below in
          accordance with this Authorization and the applicable rules of the
          National Automated Clearing House Association and its related member
          associations. The undersigned further authorizes bank employees to
          release information about the designated account to representatives of
          CollNect.
        </p>
        <p>2. Partner warrants that:</p>
        <p>
          a. The account is maintained primarily for commercial purposes and not
          personal, family or household purposes;
        </p>
        <p>
          b. The signature(s) below are all the signatures(s) necessary to make
          this Authorization effective as to debit and credit entries to the
          account; and
        </p>
        <p>
          c. CollNect shall not be liable for any act or omission of any
          automated clearing house, depository, or other person, including the
          originating depository financial institution. Partner will indemnify
          and hold CollNect harmless for any and all claims, demands, losses,
          liabilities or expense, including attorney’s fees and expenses,
          directly or indirectly resulting or arising out of the breach of these
          warranties and representations. d. Partner is responsible for $25.00
          NSF (Non-Sufficient Funds) fee per transaction.
        </p>
        <p>
          3. Partner hereby agrees to complete and execute any and all
          additional documents that the bank maintaining the account may require
          to effectuate and maintain this Authorization. This Authorization
          shall remain in full force and effect until CollNect has received at
          least thirty (30) days prior written notification from Partner of its
          termination. Partner further agrees not to terminate this
          Authorization until alternative trust fund payment arrangements
          acceptable to CollNect at their sole discretion have been made.{' '}
        </p>
        <p>
          4. This Authorization contains the entire agreement of the parties
          with respect to the subject matter herein. This Authorization may be
          amended only by a written Addendum signed by both parties
        </p>
        <p className="my-4">
          I,{' '}
          <strong>
            {registerInfo?.first_name} {registerInfo?.last_name}
          </strong>
          , as owner has approval from executive members of{' '}
          <strong>{registerInfo?.business_name}</strong> to register to be a
          retailer of CollNect Software Inc.{' '}
        </p>
        <FormGroup check>
          <Input
            type="checkbox"
            onChange={(event) => setIsCheck(event.target.checked)}
          />
          <Label check>I have read and agreed with Retailer Agreement</Label>
        </FormGroup>
        <div className="row">
          <div className="col-12">
            <hr className="devider"></hr>
          </div>
        </div>
        <div className="flex-arround mb-4">
          <OutlinedButton onClick={() => history.goBack()}>Back</OutlinedButton>
          <Button
            color="danger button-size"
            onClick={onSubmit}
            disabled={!isCheck}
            loading={submitting}
          >
            Submit
          </Button>
        </div>
      </Container>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          toggle={onCloseModal}
          className={'omny-scanBarCode__modal'}
          centered
        >
          <ModalHeader toggle={onCloseModal}>Verification</ModalHeader>
          <ModalBody>
            <VerificationCode
              desc={`Enter 6-digit verification code sent to Sale Agent ${registerInfo?.sale_agent_name}, whose phone number ends in `}
              phone={saleAgent?.sale_agent_phone?.substr(-4)}
              loading={verifying}
              isPopup
              isSkip
              onSkip={() => hanldeSubmit(true)}
              onCompleted={onVerifyCode}
            />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

RegisterTerm.propTypes = {
  history: PropTypes.any,
};

export default RegisterTerm;
