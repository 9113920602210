import React from 'react';
import { Field } from 'redux-form';
import { PhoneInput, TextInput, Select } from 'components';
import Normalizer from 'helpers/normalizer';
import Validator from 'helpers/validator';
import PropTypes from 'prop-types';
import US_STATES from './states_titlecase';
import { FormFieldMapping } from '../constants';

const BusinessInformation = ({ registerInfo, formValues }) => {
  const renderFieldError = (field) => {
    if (registerInfo && formValues) {
      const errorSnapshot = registerInfo.error_detail_snapshot;
      if (errorSnapshot) {
        return registerInfo[FormFieldMapping[field]] === formValues[field]
          ? errorSnapshot[FormFieldMapping[field]]
          : undefined;
      }
    }
    return undefined;
  };

  return (
    <div>
      <p className="fw-bold">Business information</p>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <Field
            name="businessName"
            component={TextInput}
            label="Business Name"
            normalize={Normalizer.capitalize}
            validate={[Validator.isRequired]}
            errorMessage={renderFieldError('businessName')}
          />
        </div>
        <div className="col-md-4 col-xs-12">
          <Field
            name="businessLicense"
            component={TextInput}
            label="Business License"
            validate={[Validator.isRequired]}
            pattern="\d*"
            errorMessage={renderFieldError('businessLicense')}
          />
        </div>
        <div className="col-md-4 col-xs-12">
          <Field
            name="taxId"
            component={TextInput}
            label="Tax ID"
            validate={[Validator.isRequired]}
            pattern="\d*"
            errorMessage={renderFieldError('taxId')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <Field
            name="businessPhone"
            component={PhoneInput}
            label="Phone number"
            className="omny-registerForm__phoneInput"
            normalize={Normalizer.normalizePhone}
            validate={[Validator.isRequired]}
            errorMessage={renderFieldError('businessPhone')}
          />
        </div>
        <div className="col-md-8 col-xs-12">
          <Field
            name="street"
            component={TextInput}
            label="Address"
            validate={[Validator.isRequired]}
            errorMessage={renderFieldError('street')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <Field
            name="zipCode"
            component={TextInput}
            label="Zip code"
            pattern="\d*"
            validate={[Validator.isRequired, Validator.maxLength(5)]}
            errorMessage={renderFieldError('zipCode')}
          />
        </div>
        <div className="col-md-4 col-xs-12">
          <Field
            name="state"
            component={Select}
            label="State"
            validate={[Validator.isRequired]}
            options={US_STATES}
            getValueOption={(op) => op.abbreviation}
            getLabelOption={(op) => op.name}
            errorMessage={renderFieldError('state')}
          />
        </div>
        <div className="col-md-4 col-xs-12">
          <Field
            name="city"
            component={TextInput}
            label="City"
            validate={[Validator.isRequired]}
            errorMessage={renderFieldError('city')}
          />
        </div>
      </div>
    </div>
  );
};

BusinessInformation.propTypes = {
  registerInfo: PropTypes.object,
  formValues: PropTypes.object,
};

export default BusinessInformation;
