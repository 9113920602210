import React, { useEffect, useState } from 'react';
import { reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { Button, OutlinedButton } from 'components';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import BusinessInformation from './BusinessInformation';
import ShippingAddress from './ShippingAddress';
import ApiHelper from 'helpers/apiHelper';
import * as url from 'helpers/urlHelper';
import PhotoDocument from './PhotoDocument';
import { updateRegisterInfoRequested } from 'store/actions';
import Routes from 'routes/routes';
import { debounce } from 'helpers/debounce';
import US_STATES from './states_titlecase';
import { REGISTER_STATUS } from 'constants/common';
import Normalizer from 'helpers/normalizer';

import './styles.scss';
import { useParams } from 'react-router-dom';

const selector = formValueSelector('REGISTER_BUSINESS_FORM');

const RegisterBusiness = ({ handleSubmit, initialize, history, change }) => {
  const dispatch = useDispatch();
  const { id: registerId } = useParams();

  const zipCode = useSelector((state) => selector(state, 'zipCode'));
  const shippingZipCode = useSelector((state) =>
    selector(state, 'shippingZipCode'),
  );
  const isSameBusinessAddress = useSelector((state) =>
    selector(state, 'isSameBusinessAddress'),
  );

  const formValues = useSelector((state) =>
    getFormValues('REGISTER_BUSINESS_FORM')(state),
  );

  const registerInfo = useSelector((state) => state.app.Register.registerInfo);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (registerId) {
      getRegisterInfo(registerId);
    }
  }, [registerId]);

  useEffect(() => {
    if (zipCode?.length === 5) {
      debounce(() =>
        getAddressByZipCode(zipCode).then((res) => {
          change('city', res.city);
          const matchState = US_STATES.find(
            (state) => state.abbreviation === res.state,
          );
          if (matchState) {
            change('state', matchState?.abbreviation);
          }
        }),
      )();
    }
  }, [zipCode]);

  useEffect(() => {
    if (shippingZipCode?.length === 5) {
      debounce(() =>
        getAddressByZipCode(shippingZipCode).then((res) => {
          change('shippingCity', res.city);
          const matchState = US_STATES.find(
            (state) => state.abbreviation === res.state,
          );
          if (matchState) {
            change('shippingState', matchState?.abbreviation);
          }
        }),
      )();
    }
  }, [shippingZipCode]);

  useEffect(() => {
    if (registerInfo) {
      const phone =
        registerInfo.status === REGISTER_STATUS.new ? registerInfo.phone : null;
      initialize({
        businessName: registerInfo.business_name,
        businessLicense: registerInfo.business_license,
        taxId: registerInfo.tax_id,
        businessPhone: Normalizer.normalizePhone(
          registerInfo.business_phone || phone,
        ),
        zipCode: registerInfo.zip_code,
        businessLicenseImg: registerInfo.business_license_image_url,
        driverLicenseImg: registerInfo.driver_license_image_url,
        frontStoreImg: registerInfo.store_image_url,
        city: registerInfo.city,
        state: registerInfo.state,
        street: registerInfo.street,
        shippingName: registerInfo.shipping_name,
        shippingPhone: Normalizer.normalizePhone(
          registerInfo.shipping_phone || phone,
        ),
        shippingStreet: registerInfo.shipping_street,
        shippingCity: registerInfo.shipping_city,
        shippingState: registerInfo.shipping_state,
        shippingZipCode: registerInfo.shipping_zip_code,
        errorDetailSnapshot: registerInfo.error_detail_snapshot,
        isSameBusinessAddress: true,
      });
    }
  }, []);

  const getRegisterInfo = async (registerId) => {
    try {
      const res = await ApiHelper.get(
        url.GET_REGISTER_INFO.replace('{registerId}', registerId),
      );
      dispatch(updateRegisterInfoRequested(res));
    } catch (error) {}
  };

  const onSumbit = async (values) => {
    const payload = {
      business_name: values.businessName,
      business_license: values.businessLicense,
      tax_id: values.taxId,
      business_phone: values.businessPhone.replaceAll('-', ''),
      zip_code: values.zipCode,
      business_license_image_url: values.businessLicenseImg,
      driver_license_image_url: values.driverLicenseImg,
      store_image_url: values.frontStoreImg,
      city: values.city,
      state: values.state,
      street: values.street,
      shipping_name: isSameBusinessAddress
        ? values.businessName
        : values.shippingName,
      shipping_phone: isSameBusinessAddress
        ? values.businessPhone.replaceAll('-', '')
        : values.shippingPhone.replaceAll('-', ''),
      shipping_street: isSameBusinessAddress
        ? values.street
        : values.shippingStreet,
      shipping_city: isSameBusinessAddress ? values.city : values.shippingCity,
      shipping_state: isSameBusinessAddress
        ? values.state
        : values.shippingState,
      shipping_zip_code: isSameBusinessAddress
        ? values.zipCode
        : values.shippingZipCode,
    };
    try {
      setSubmitting(true);
      const response = await ApiHelper.post(
        url.POST_REGISTER_BUSINESS_INFO.replace(
          '{registerId}',
          registerInfo?.id,
        ),
        payload,
      );
      dispatch(updateRegisterInfoRequested(response));
      history.push(Routes.REGISTER_BANK.replace(':id', response.id));
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const getAddressByZipCode = async (code) => {
    try {
      return await ApiHelper.get(url.GET_ADDRESS_BY_ZIP_CODE, {
        params: {
          code,
        },
      });
    } catch (error) {}
  };

  const onBack = () => {
    history.push(Routes.REGISTER_CONTACT);
  };

  return (
    <div className="omny-registerBusiness">
      <BusinessInformation
        registerInfo={registerInfo}
        formValues={formValues}
      />
      <ShippingAddress
        isSameBusinessAddress={isSameBusinessAddress}
        registerInfo={registerInfo}
        formValues={formValues}
      />
      <PhotoDocument registerInfo={registerInfo} formValues={formValues} />
      <div className="row">
        <div className="col-12">
          <hr className="devider"></hr>
        </div>
      </div>
      <div className="flex-arround">
        <OutlinedButton onClick={onBack}>Back</OutlinedButton>
        <Button
          color="danger button-size"
          onClick={handleSubmit(onSumbit)}
          loading={submitting}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

RegisterBusiness.propTypes = {
  handleSubmit: PropTypes.func,
  history: PropTypes.any,
  initialize: PropTypes.func,
  change: PropTypes.func,
};

export default reduxForm({
  form: 'REGISTER_BUSINESS_FORM',
})(RegisterBusiness);
