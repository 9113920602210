import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CustomerItem from './CustomerItem';
import './styles.scss';

const CustomerInfo = ({ customerName, customerPhone }) => {
  const className = classnames('omny-customerInfo');
  return (
    <div className={className}>
      <CustomerItem label="Customer's Phone Number" value={customerPhone} />
      {customerName && (
        <CustomerItem
          label="Name"
          value={customerName}
          className="omny-customerInfo__customerName"
        />
      )}
    </div>
  );
};

CustomerInfo.propTypes = {
  customerName: PropTypes.string,
  customerPhone: PropTypes.string,
};

export default CustomerInfo;
