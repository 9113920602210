const normalizePhone = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10,
  )}`;
};

const capitalize = (value) => {
  const words = value.split(' ');
  const capitalized = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1),
  );
  return capitalized.join(' ');
};

const uppercase = (value) => {
  return value.toUpperCase();
};

const removeDecimalZeroFormat = (numb) => {
  if (!numb) return;
  return numb.toString().replace(/([0-9]*)\.0+$/gm, '$1');
};

const formatSecurePhoneNumber = (phone) => {
  if (phone != null && phone != '') {
    return `${phone.substring(0, 3)}***${phone.substring(phone.length - 3)}`;
  }
  return '';
};

const formatSecureCardNumber = (cardNumber) => {
  if (cardNumber != null && cardNumber != '') {
    if (cardNumber.length > 6) {
      return `${cardNumber.substring(0, 3)}***${cardNumber.substring(
        cardNumber.length - 3,
      )}`;
    }
    return cardNumber;
  }

  return '';
};

const detectMob = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export default {
  normalizePhone,
  capitalize,
  uppercase,
  removeDecimalZeroFormat,
  formatSecurePhoneNumber,
  formatSecureCardNumber,
  detectMob,
};
