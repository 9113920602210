import React from 'react';
import PropTypes from 'prop-types';

const OrderItem = ({ label, value }) => {
  return (
    <div className="omny-reloadCardResult__orderItem">
      <p>{label}</p>
      <h6>{value}</h6>
    </div>
  );
};

OrderItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export default OrderItem;
