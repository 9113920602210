import React, { useEffect, useState } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { PhoneInput, TextInput, Button, Select } from 'components';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import Normalizer from 'helpers/normalizer';
import Validator from 'helpers/validator';

import * as url from 'helpers/urlHelper';
import ApiHelper from 'helpers/apiHelper';
import PropTypes from 'prop-types';
import Routes from 'routes/routes';
import {
  getPhoneRegisterRequested,
  updateRegisterInfoRequested,
} from 'store/actions';
import { useDispatch } from 'react-redux';
import { REGISTER_STATUS } from 'constants/common';
import './styles.scss';
import VerificationCode from 'components/VerificationCode';

const selector = formValueSelector('REGISTER_CONTACT_FORM');

const RegisterContact = ({ handleSubmit, history, initialize }) => {
  const phone = useSelector((state) => selector(state, 'phone'));
  const dispatch = useDispatch();
  const { registerInfo, phoneStatus } = useSelector(
    (state) => state.app.Register,
  );
  const [saleAgentOptions, setSalesAgentOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [verifyCount, setVerifyCount] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [registerID, setRegisterId] = useState(registerInfo?.id);

  useEffect(() => {
    if (registerInfo) {
      initialize({
        phone: Normalizer.normalizePhone(registerInfo.phone),
        firstName: registerInfo.first_name,
        lastName: registerInfo.last_name,
        email: registerInfo.email,
        preferredName: registerInfo.name,
        saleAgentName: registerInfo.sale_agent_name,
      });
    }
    getSaleAgents();
  }, []);

  useEffect(() => {
    if (verifyCount === 5) {
      history.replace(
        Routes.REGISTER_MANY_OTP_REQUEST.replace(':id', registerID),
      );
    }
  }, [verifyCount]);

  const onCheckRegisterPhone = async (values) => {
    setLoading(true);
    try {
      const response = await ApiHelper.get(url.GET_REGISTER_BY_PHONE, {
        params: {
          phone: phone.replaceAll('-', ''),
        },
      });
      await onSendSMS(response.id);
      dispatch(getPhoneRegisterRequested(response));
      const registeInfo = await getRegisterInfo(response.id);
      dispatch(updateRegisterInfoRequested(registeInfo));
      setRegisterId(response.id);
      setModalOpen(true);
      // history.push(Routes.REGISTER_VERIFICATION.replace(':id', response.id));
    } catch (error) {
      initRegister(values);
    } finally {
      setLoading(false);
    }
  };

  const onSendSMS = async (registerId) => {
    try {
      await ApiHelper.post(
        url.POST_REGISTER_RESEND_SMS.replace('{registerId}', registerId),
      );
    } catch (error) {}
  };

  const getRegisterInfo = async (registerId) => {
    try {
      return await ApiHelper.get(
        url.GET_REGISTER_INFO.replace('{registerId}', registerId),
      );
    } catch (error) {
      return undefined;
    }
  };

  const getSaleAgents = async () => {
    try {
      const res = await ApiHelper.get(url.GET_SALE_AGENT);
      setSalesAgentOptions(res);
    } catch (error) {}
  };

  const onSendContactInformation = async (values) => {
    setLoading(true);
    const payload = {
      id: registerInfo?.id,
      email: values.email,
      name: values.preferredName,
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phone.replaceAll('-', ''),
      sale_agent_name: values.saleAgentName,
    };
    try {
      const response = await ApiHelper.post(
        url.POST_REGISTER_CONTACT_INFO.replace(
          '{registerId}',
          registerInfo?.id,
        ),
        payload,
      );
      dispatch(updateRegisterInfoRequested(response));
      setRegisterId(response.id);

      if (response.phone_verified) {
        history.push(Routes.REGISTER_BUSINESS.replace(':id', response.id));
      } else {
        setModalOpen(true);
        // history.push(Routes.REGISTER_VERIFICATION.replace(':id', response.id));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const initRegister = async (values) => {
    setLoading(true);
    const payload = {
      email: values.email,
      name: values.preferredName,
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phone.replaceAll('-', ''),
      sale_agent_name: values.saleAgentName,
    };
    try {
      const response = await ApiHelper.post(url.GET_REGISTER_BY_PHONE, payload);
      dispatch(updateRegisterInfoRequested(response));
      setRegisterId(response.id);
      setModalOpen(true);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onSumbit = (values) => {
    if (registerInfo) {
      onSendContactInformation(values);
    } else {
      onCheckRegisterPhone(values);
    }
  };

  const onVerifyCode = async (code) => {
    try {
      setSubmitting(true);
      setVerifyCount(verifyCount + 1);
      await ApiHelper.post(
        url.POST_REGISTER_VERIFICATION_CODE.replace('{registerId}', registerID),
        { code },
      );
      if (phoneStatus && phoneStatus.status === REGISTER_STATUS.new) {
        return history.replace(Routes.REGISTER_NEW.replace(':id', registerID));
      }
      if (
        [
          REGISTER_STATUS.approved,
          REGISTER_STATUS.activated,
          REGISTER_STATUS.waiting_for_approval,
        ].includes(registerInfo.status)
      ) {
        return history.replace(
          Routes.REGISTER_ALREADY_IN_USE.replace(':id', registerID),
        );
      }

      if (registerInfo.status === REGISTER_STATUS.rejected) {
        return history.replace(
          Routes.REGISTER_REJECTED.replace(':id', registerID),
        );
      }

      return history.replace(
        Routes.REGISTER_BUSINESS.replace(':id', registerID),
      );
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const onCloseModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div className="omny-registerContact">
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <Field
            name="firstName"
            component={TextInput}
            label="First Name"
            normalize={Normalizer.capitalize}
            validate={[Validator.isRequired]}
          />
        </div>
        <div className="col-md-4 col-xs-12">
          <Field
            name="lastName"
            component={TextInput}
            label="Last Name"
            normalize={Normalizer.capitalize}
            validate={[Validator.isRequired]}
          />
        </div>
        <div className="col-md-4 col-xs-12">
          <Field
            name="preferredName"
            component={TextInput}
            label="Preferred Name (optional)"
            normalize={Normalizer.capitalize}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <Field
            name="phone"
            component={PhoneInput}
            label="Phone number"
            className="omny-registerContact__phoneInput"
            normalize={Normalizer.normalizePhone}
            validate={[Validator.isRequired]}
          />
          <p className="omny-registerContact__desc">
            The verification code will be sent to your phone number via text
            message.
          </p>
        </div>
        <div className="col-md-4 col-xs-12">
          <Field
            name="email"
            component={TextInput}
            label="Email"
            type="email"
            validate={[Validator.isRequired, Validator.isEmail]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <Field
            name="saleAgentName"
            component={Select}
            options={saleAgentOptions}
            label="Sale Agent"
            getValueOption={(op) => op.name}
            getLabelOption={(op) => op.name}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <hr className="devider"></hr>
        </div>
      </div>
      <div className="d-grid flex-end">
        <Button
          color="danger button-size"
          onClick={handleSubmit(onSumbit)}
          loading={loading}
        >
          Next
        </Button>
      </div>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          toggle={onCloseModal}
          className={'omny-scanBarCode__modal'}
          centered
        >
          <ModalHeader toggle={onCloseModal}>
            Verification phone number
          </ModalHeader>
          <ModalBody>
            <VerificationCode
              desc={'Enter 6-digit verification just send to your mobile phone'}
              loading={submitting}
              isPopup
              onCompleted={onVerifyCode}
            />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

RegisterContact.propTypes = {
  handleSubmit: PropTypes.func,
  history: PropTypes.any,
  initialize: PropTypes.func,
};

export default reduxForm({
  form: 'REGISTER_CONTACT_FORM',
})(RegisterContact);
