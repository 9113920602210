import { Button } from 'components';
import React from 'react';
import { Container } from 'reactstrap';
import CircleInfoIcon from './circle-info.svg';
import './styles.scss';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Routes from 'routes/routes';

const RegisterNew = ({ history }) => {
  const { phoneStatus } = useSelector((state) => state.app.Register);

  const onContinueRegister = () => {
    history.push(Routes.REGISTER_BUSINESS.replace(':id', phoneStatus.id));
  };

  return (
    <div className="omny-registerStatus">
      <Container>
        <div>
          <div className="text-center">
            <img src={CircleInfoIcon} alt="circle-checkbox-icon" />
          </div>
          <p className="text-center mt-4">
            {
              "You have registered with phone number. You can continue register account by click on 'Continue Register' button below."
            }
          </p>
        </div>
        <div className="d-grid mt-5 justify-content-center">
          <Button color="danger button-size" onClick={onContinueRegister}>
            Continue Register
          </Button>
        </div>
      </Container>
    </div>
  );
};

RegisterNew.propTypes = {
  history: PropTypes.any,
};

export default RegisterNew;
