import React from 'react';
import { Field } from 'redux-form';
import { PhoneInput, TextInput } from 'components';
import PropTypes from 'prop-types';
import Normalizer from 'helpers/normalizer';
import Validator from 'helpers/validator';
import { FormFieldMapping } from '../constants';

const BankInformation = ({ registerInfo, formValues }) => {
  const renderFieldError = (field) => {
    if (registerInfo && formValues) {
      const errorSnapshot = registerInfo.error_detail_snapshot;
      if (errorSnapshot) {
        return registerInfo[FormFieldMapping[field]] === formValues[field]
          ? errorSnapshot[FormFieldMapping[field]]
          : undefined;
      }
    }
    return undefined;
  };

  return (
    <div>
      <p className="fw-bold">Bank information</p>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <Field
            name="bankName"
            component={TextInput}
            label="Bank name"
            normalize={Normalizer.capitalize}
            validate={[Validator.isRequired]}
            errorMessage={renderFieldError('bankName')}
          />
        </div>
        <div className="col-md-4 col-xs-12">
          <Field
            name="bankAccountHolder"
            component={TextInput}
            label="Bank account holder"
            normalize={Normalizer.uppercase}
            validate={[Validator.isRequired]}
            errorMessage={renderFieldError('bankAccountHolder')}
          />
        </div>
        <div className="col-md-4 col-xs-12">
          <Field
            name="bankAccountNumber"
            component={TextInput}
            label="Bank account number"
            validate={[Validator.isRequired]}
            pattern="\d*"
            errorMessage={renderFieldError('bankAccountNumber')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <Field
            name="bankRoutingNumber"
            component={PhoneInput}
            label="Routing number"
            className="omny-registerForm__phoneInput"
            validate={[Validator.isRequired]}
            pattern="\d*"
            errorMessage={renderFieldError('bankRoutingNumber')}
          />
        </div>
      </div>
    </div>
  );
};

BankInformation.propTypes = {
  registerInfo: PropTypes.object,
  formValues: PropTypes.object,
};

export default BankInformation;
