import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

//Import Images
import OMNYLogo from 'assets/img/OMNYLogo.svg';
import maintanence from 'assets/img/coming-soon.svg';
import './styles.scss';

const PagesComingsoon = () => {
  //meta title
  document.title = 'Coming Soon';

  return (
    <React.Fragment>
      <div className="coming-soon pt-sm-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center">
                <Link to="/" className="d-block auth-logo">
                  <img src={OMNYLogo} alt="logo" width="200" />
                </Link>
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">Something Awesome Is In The Work</h4>
                <p className="text-muted">
                  We&lsquo;ll be up and running soon with our new and improved
                  website.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PagesComingsoon;
