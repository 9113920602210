import {
  GET_PHONE_REGISTER_REQUESTED,
  GET_PHONE_REGISTER_SUCCESS,
  UPDATE_REGISTER_INFO_REQUESTED,
  UPDATE_REGISTER_INFO_SUCCESS,
} from './actionTypes';

export const getPhoneRegisterRequested = (registerInfo) => ({
  type: GET_PHONE_REGISTER_REQUESTED,
  payload: registerInfo,
});

export const getPhoneRegisterSuccess = (registerInfo) => ({
  type: GET_PHONE_REGISTER_SUCCESS,
  payload: registerInfo,
});

export const updateRegisterInfoRequested = (registerInfo) => ({
  type: UPDATE_REGISTER_INFO_REQUESTED,
  payload: registerInfo,
});

export const updateRegisterInfoSuccess = (registerInfo) => ({
  type: UPDATE_REGISTER_INFO_SUCCESS,
  payload: registerInfo,
});
