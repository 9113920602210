import React, { useEffect, useState } from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import scanBarcodeExample from '../../assets/img/scanBarcodeExample.png';
import PropTypes from 'prop-types';

export default function Scanner(props) {
  const { onScanComplete } = props;
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const layout = document.getElementsByClassName('omny-layout');
    if (layout[0]) {
      layout[0].classList.add('full-layout');
    }

    return () => {
      if (layout[0]) {
        layout[0].classList.remove('full-layout');
      }
    };
  }, []);

  const onInstructionsClick = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div className="omny-scanBarCode__scannerContainer">
      <>
        <BarcodeScannerComponent
          width={'inherit'}
          torch={false}
          onUpdate={(error, result) => {
            if (result) {
              if (result.text) {
                onScanComplete(result.text);
              }
            }
          }}
        />
        <div className="omny-scanBarCode__bottom">
          <h3>Scan your OMNY card</h3>
          <Button
            color="btn btn-primary btn-lg omny-button"
            onClick={onInstructionsClick}
          >
            {' '}
            How to scan?
          </Button>
        </div>
        <div className="omny-scanBarCode__bottomBackground"></div>

        {modalOpen && (
          <Modal
            isOpen={modalOpen}
            toggle={onInstructionsClick}
            className={'omny-scanBarCode__modal'}
            centered
          >
            <ModalHeader toggle={onInstructionsClick}>Instruction</ModalHeader>
            <ModalBody>
              <p>
                {"Point your phone's camera to the OMNY card holder to scan"}
              </p>
              <img src={scanBarcodeExample} alt="instructions" />
            </ModalBody>
          </Modal>
        )}
      </>
    </div>
  );
}

Scanner.propTypes = {
  onScanComplete: PropTypes.func,
};
