import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import './styles.scss';
import OrderItem from './OrderItem';
import { Button } from 'components';
import PreOrderHeader from './PreOrderHeader';
import Routes from 'routes/routes';
import PropTypes from 'prop-types';
import ApiHelper from 'helpers/apiHelper';
import Normalizer from 'helpers/normalizer';
import * as url from 'helpers/urlHelper';

const ReloadCardResult = (props) => {
  const [preOrder, setPreOrder] = useState();
  const { id } = useParams();

  useEffect(() => {
    getPreOrderDetail();
  }, []);

  const getPreOrderDetail = async () => {
    try {
      const response = await ApiHelper.get(
        url.GET_PRE_ORDER.replace('{id}', id),
      );
      setPreOrder(response);
    } catch (error) {}
  };
  const onNext = () => {
    props.history.push(`${Routes.RELOAD_CARD_RESULT_NEXT.replace(':id', id)}`);
  };

  return (
    <div className="omny-reloadCardResult">
      <Container>
        <PreOrderHeader seq={preOrder?.seq} date={preOrder?.created_at} />
        <p className="omny-reloadCardResult__note text-center">
          Your order id has been sent to your phone
        </p>
        <div className="omny-reloadCardResult__orderInfo">
          <OrderItem
            label="Enter Your Phone Number"
            value={Normalizer.formatSecurePhoneNumber(
              Normalizer.normalizePhone(preOrder?.customer_phone),
            )}
          />
          {preOrder?.customer_name && (
            <OrderItem label="Name" value={preOrder.customer_name} />
          )}
          <OrderItem
            label="OMNY card number"
            value={Normalizer.formatSecureCardNumber(
              `${preOrder?.product_prefix}${preOrder?.product_pin}`,
            )}
          />
          <OrderItem
            label="Amount"
            value={`$${Normalizer.removeDecimalZeroFormat(preOrder?.amount)}`}
          />
        </div>
        <div className="d-grid">
          <Button color="primary" onClick={onNext}>
            Next
          </Button>
        </div>
      </Container>
    </div>
  );
};

ReloadCardResult.propTypes = {
  history: PropTypes.any,
};

export default ReloadCardResult;
