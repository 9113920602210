import {
  GET_PHONE_REGISTER_SUCCESS,
  UPDATE_REGISTER_INFO_SUCCESS,
} from './actionTypes';

const Register = (state = {}, action) => {
  switch (action.type) {
    case GET_PHONE_REGISTER_SUCCESS:
      return {
        ...state,
        phoneStatus: action.payload,
      };
    case UPDATE_REGISTER_INFO_SUCCESS:
      return {
        ...state,
        registerInfo: action.payload,
      };
    default:
      return state;
  }
};

export default Register;
